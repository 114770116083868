import React, { useEffect, useState } from 'react'
import { Box, Grid, } from "@mui/material";
import TextLabel from '../Common/Fields/TextLabel';
import CommonTextField from '../Common/Fields/TextField';
import CommonButton from '../Common/Button/CommonButton';

const AddSitePopularity = ({ data, error, handleChange, isEdit, onSubmit, setSelectedCountry }) => {

    return (
        <Box>
            <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CommonTextField
                        fontWeight={400}
                        text={'Site popularity'}
                        placeholder={"Enter site popularity"}
                        type='text'
                        name='sitePopularity'
                        value={data?.sitePopularity}
                        onChange={(e) => handleChange(e, false)}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.sitePopularity ? error?.sitePopularity : ""} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CommonTextField
                        fontWeight={400}
                        text={'Site popularity description'}
                        placeholder={"Enter site popularity description"}
                        type='text'
                        name='description'
                        value={data?.description}
                        onChange={(e) => handleChange(e, false)}
                        multiline={true}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.description ? error?.description : ""} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
                        <CommonButton
                            width={'280px'}
                            text={`${isEdit ? "Update" : "Create"} site popularity`}
                            type="submit"
                            onClick={onSubmit}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AddSitePopularity