import React, { useEffect, useState } from "react";
import PaperContainer from "../Common/PaperContainer";
import { Box, Card, Grid, Typography, } from "@mui/material";
import axios from "../../APiSetUp/axios";
import DashboardSummaryBox from "../Common/DashboardSummaryBox";
import { useAppContext } from "../../Context/context";
import { globalAmountConfig } from "../../Utils/globalConfig";
import WidgetLoader from "../Common/widgetLoader";
import CommonButton from "../Common/Button/CommonButton";
import swal from "sweetalert";

const Attendance = () => {
    const { OnUpdateError, toggleLoader, user, onUpdateUser, menuList } = useAppContext();

    const [loading, setLoading] = useState(false)
    const [currentTime, setCurrentTime] = useState(new Date());

    const formattedTime = currentTime.toLocaleTimeString();

    const formattedDate = currentTime.toLocaleDateString(undefined, {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);


    const handaleClockIn = () => {
        toggleLoader();
        let body = {
            "employeId": user?._id,
            "date": currentTime
        };
        console.log('body', body)
        axios
            .post(`clockIn`, body)
            .then((res) => {
                if (res?.data?.data) {
                    swal("Success", res?.data?.message, "success");
                }
                toggleLoader();
            })
            .catch((err) => {
                toggleLoader();
                OnUpdateError(err.data.message);
            });
    }

    const handaleClockOut = () => {
        toggleLoader();
        let body = {
            "employeId": user?._id,
            "date": currentTime
        };
        axios
            .post(`clockOut`, body)
            .then((res) => {
                if (res?.data?.data) {
                    swal("Success", res?.data?.message, "success");
                }
                toggleLoader();
            })
            .catch((err) => {
                toggleLoader();
                OnUpdateError(err.data.message);
            });
    }

    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <PaperContainer sx={{ padding: 1 }} elevation={0}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={1} sx={{ display: "flex", justifyContent: "end" }}>
                        <Grid item xs={12} sm={12} md={2.5} lg={2.5} sx={{ display: "flex", justifyContent: { xs: "center", sm: "center", md: "end", lg: "end" }, alignItems: "center" }}>
                            <Box style={{ textAlign: 'center', alignItems: "center" }} >
                                <Typography variant="h4" color={"grey"}>{formattedTime}</Typography>
                                <Typography variant="p">{formattedDate}</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={2}>
                            <Box style={{ textAlign: 'center', alignItems: "center" }} >
                                <Box sx={{ padding: "10px" }}>
                                    <CommonButton
                                        text={"Web Clock-in"}
                                        width={"10rem"}
                                        onClick={handaleClockIn}
                                    />
                                </Box>
                                <Box sx={{ padding: "10px" }}>
                                    <CommonButton
                                        text={"Web Clock-out"}
                                        color={"error"}
                                        width={"10rem"}
                                        onClick={handaleClockOut}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </PaperContainer >
            </Grid >
        </>
    )
}

export default Attendance
