import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from './Pages/login';
import Loader from './Components/Loader';
import { ThemeProvider } from "@mui/material";
import { lightTheme } from "./theme";
import User from "./Pages/User";
import Profile from "./Pages/Profile";
import Dashboard from "./Pages/dashboard";
import Layout from "./Components/Layout";
import { ProtectedRoutes } from "./Routes/ProtectedRoutes";
import OTPverification from "./Pages/OTPverification";
import ForgetPassword from "./Pages/forgetPassword";
import Resetpassword from "./Pages/resetpassword";
import { useAppContext } from "./Context/context";
import Designation from "./Pages/designation";
import SiteType from "./Pages/sIteType";
import Site from "./Pages/site";
import EmployeAttendance from "./Pages/employeAttendance";
import Project from "./Pages/project";
import SiteCategory from "./Pages/siteCategory";
import SitePopularity from "./Pages/sitePopularity";
import Ownership from "./Pages/ownership";
import TrailName from "./Pages/trailNumber";

function App() {
  const { user } = useAppContext();
  return (
    <BrowserRouter basename={"/"}>
      <ThemeProvider theme={lightTheme}>
        <Loader />
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />
            {/* <Route path="/register" element={<Register />} /> */}
            <Route path="/forgot-password" element={<ForgetPassword />} />
            <Route path="/otp-verification" element={<OTPverification />} />
            <Route path="/reset-password" element={<Resetpassword />} />
            <Route element={<ProtectedRoutes />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/user" element={<User />} />
              <Route path="/designation" element={<Designation />} />
              <Route path="/site-type" element={<SiteType />} />
              <Route path="/project" element={<Project />} /> 
              <Route path="/site-category" element={<SiteCategory />} />
              <Route path="/site-popularity" element={<SitePopularity />} />
              <Route path="/ownership" element={<Ownership />} />
              <Route path="/site" element={<Site />} />
              <Route path="/attendance" element={<EmployeAttendance />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/trail-name" element={<TrailName />} />

            </Route>
          </Routes>
        </Layout>
        <Routes>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>


  );
}

export default App;