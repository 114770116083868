import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Table, TableRow, TableHead, Box, Grid, TableContainer, Tabs, Tab, Fab, Button, Badge, CardMedia } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Assets from "../../Components/Common/ImageContainer";
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import { lightTheme } from "../../theme";
import { useAppContext } from "../../Context/context";
import axios, { Image_BASE_URL } from "../../APiSetUp/axios";
import CommonModal from "../../Components/Common/CommonModel";
import swal from "sweetalert";
import { Roles, permissionStatus, siteStatus } from "../../Utils/enum";
import { useLocation } from "react-router-dom";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Swal from "sweetalert2";
import WidgetLoader from "../../Components/Common/widgetLoader";
import CustomPagination from "../../Components/Common/CustomPagination";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import CommonButton from "../../Components/Common/Button/CommonButton";
import AddSite from "../../Components/Site";
import TuneIcon from '@mui/icons-material/Tune';
import SideDrawer from "../../Components/Common/SideDrawer";
import SitFiltercontent from "../../Components/Common/SitFiltercontent";
import ViewSite from "../../Components/SiteModel/viewSite";
import ReadMore from "../../Components/Common/ReadMore";
import DownloadIcon from '@mui/icons-material/Download';
import { downloadFileFromBlob } from "../../Utils/helper";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.primary.main,
    fontFamily: "Poppins",
    whiteSpace: "nowrap",
    padding: 8
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles()((theme) => {
  return {
    paddedRow: {
      padding: "15px 10px",
    },
    writeBox: {
      borderRadius: "6px",
      padding: "6px",
      backgroundColor: lightTheme.palette.bgLightExtraPrimary.main,
      color: lightTheme.palette.primary.main,
      cursor: "pointer",
      height: '26px'
    },
    viewBox: {
      borderRadius: "6px",
      padding: "6px",
      color: lightTheme.palette.bgLightSuccess.main,
      backgroundColor: lightTheme.palette.bgLightExtraSuccess.main,
      cursor: "pointer",
      height: '26px'
    },
    deleteBox: {
      borderRadius: "6px",
      padding: "6px",
      color: lightTheme.palette.bgLightRed.main,
      backgroundColor: lightTheme.palette.bgLightExtraRed.main,
      cursor: "pointer",
      height: '26px'
    },
  };
});
const siteStatusConfig = [
  {
    statusName: "Approval pending",
    statusId: 0,
  },
  {
    statusName: "Approved By SuperAdmin",
    statusId: 1,
  },
  {
    statusName: "Rejected By SuperAdmin",
    statusId: 2,
  },
  {
    statusName: "Approved By Admin",
    statusId: 3,
  },
  {
    statusName: "Rejected By Admin",
    statusId: 4,
  },
];

const statusColors = {
  0: "#FDCF71",
  1: "#2DB70E",
  2: "#FF7474",
  3: "#2DB70E",
  4: "#FF7474",
};
const Site = () => {
  const { classes } = useStyles();
  const { OnUpdateError, toggleLoader, menuList, user } = useAppContext();
  const location = useLocation();
  const { pathname } = location;

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  const videoExtensions = ['mp4', 'avi', 'mov'];
  const pdfExtensions = ['pdf'];
  const doumentsExtensions = ['doc', 'docx',];
  const excelExtensions = ['xls', 'xlsx'];
  const audioExtensions = ['mp3', 'wav'];

  //States
  const [data, setData] = useState({
    _id: "",
    project: "",
    state: "",
    city: "",
    siteName: "",
    siteType: "",
    location: "",
    latitude: "",
    longitude: "",
    siteCategory: "",
    subCategory: "",
    sitePopularity: "",
    ownership: "",
    conservation: "",
    graded: "",
    description: "",
    associatedCommunity: "",
    employeId: "",
    sietLogo: "",
  });

  console.log('data111111', data)

  const [siteData, setSiteData] = useState([]);
  const [model, setModel] = useState(false);
  const [viewModel, setViewModel] = useState(false);
  const [error, setError] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [permissions, setPermissions] = useState({});
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([]);

  const [siteTypeList, setSiteTypeList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [sitePopularityList, setSitePopularityList] = useState([]);
  const [employeList, setEmployeList] = useState([]);
  const [ownershipList, setOwnershipList] = useState([]);
  const [trailNameList, setTrailNameList] = useState([]);
  const [siteCategoryList, setSiteCategoryList] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [tabValue, setTabValue] = React.useState(0);

  const [filterImageData, setFilterImageData] = useState({
    imageData: [],
    pdfData: [],
    documentData: [],
    excelData: [],
    videoData: [],
    audioData: []
  })
  const [filterData, setFilterData] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);

  const _getDefaultId = (data, name) => {
    return data?.length > 0 && data?.filter((e) => e.name == name)?.[0]?.id;
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const addAudioFile = () => {
    setFiles([...files, null]);
  };

  const deleteFiled = (ele, index) => {
    const input = [...files]
    input.splice(index, 1);
    setFiles(input)
  };

  //Validation
  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};

    if (!data?.project) {
      formIsValid = false;
      errors["project"] = "Please select project.";
    }
    if (!data?.state) {
      formIsValid = false;
      errors["state"] = "Please select state.";
    }
    if (!data?.city) {
      formIsValid = false;
      errors["city"] = "Please select district.";
    }
    if (!data?.siteName) {
      formIsValid = false;
      errors["siteName"] = "Please enter title.";
    }
    if (!data?.siteType) {
      formIsValid = false;
      errors["siteType"] = "Please select Site Type.";
    }
    if (!data?.location) {
      formIsValid = false;
      errors["location"] = "Please enter location.";
    }
    if (!data?.latitude) {
      formIsValid = false;
      errors["latitude"] = "Please enter latitude.";
    }
    if (!data?.longitude) {
      formIsValid = false;
      errors["longitude"] = "Please enter longitude.";
    }
    if (!data?.siteCategory) {
      formIsValid = false;
      errors["siteCategory"] = "Please select site category";
    }
    if (!data?.subCategory) {
      formIsValid = false;
      errors["subCategory"] = "Please enter sub category.";
    }
    if (!data?.sitePopularity) {
      formIsValid = false;
      errors["sitePopularity"] = "Please select site popularity.";
    }
    if (!data?.ownership) {
      formIsValid = false;
      errors["ownership"] = "Please enter sub ownership.";
    }
    if (!data?.conservation) {
      formIsValid = false;
      errors["conservation"] = "Please enter sub conservation.";
    }
    if (!data?.graded) {
      formIsValid = false;
      errors["graded"] = "Please enter sub graded.";
    }

    if (!data?.description) {
      formIsValid = false;
      errors["description"] = "Please enter sub description.";
    }
    // else if (countWords(data?.description) > 20) {
    //   formIsValid = false;
    //   errors["description"] = "You have exceeded the 20-word limit.";
    // }

    if (!data?.associatedCommunity) {
      formIsValid = false;
      errors["associatedCommunity"] = "Please enter associatedCommunity.";
    }
    // else if (countWords(data?.associatedCommunity) > 20) {
    //   formIsValid = false;
    //   errors["associatedCommunity"] = "You have exceeded the 20-word limit.";
    // }

    if (!data?.sietLogo) {
      formIsValid = false;
      errors["sietLogo"] = "Please select site logo.";
    }

    setError(errors);
    return formIsValid;
  };

  const handleChange = (e, newValue) => {
    const { name, value } = e.target;
    setData((prevState) => {
      if (name === "siteType") {
        return {
          ...prevState,
          siteType: newValue,
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  // const handleUpload = async (file, index) => {
  //   const formData = new FormData();
  //   formData.append("image", file);
  //   toggleLoader();
  //   if (file?.size <= 10000000) {
  //     axios
  //       .post("/upload/image/attachment", formData)
  //       .then((res) => {
  //         if (res?.data?.data) {
  //           const img = [...files]
  //           img[index] = res?.data?.data?.image;
  //           setFiles(img)
  //         }
  //       }).catch((err) => {
  //         toggleLoader();
  //         OnUpdateError(err.data.message);
  //       });
  //   } else {
  //     setError({ ...error, audioSizeValid: "Upload file allowed size is 10MB", });
  //   }
  //   toggleLoader();
  // };


  const handleUpload = (file) => {
    var formData = new FormData();
    for (const key in file) {
      formData.append("image", file[key]);
    }
    toggleLoader();
    axios
      .post("upload/multiple/image", formData)
      .then((res) => {
        if (res?.data?.data) {
          const images = res?.data?.data?.reduce((acc, obj) => { acc.push(obj.image); return acc; }, []);
          setFiles((prevFiles) => [...prevFiles, ...images]);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      })
      .finally(() => {
        toggleLoader();
      });
  };

  // const handleDeleteFile = (value, index) => {
  //   let body = {
  //     url: files[index],
  //   };
  //   axios
  //     .post(`/upload/delete_file`, body)
  //     .then((res) => {
  //       swal("Success", res?.data?.message, "success");
  //       const img = [...files]
  //       img[index] = null;
  //       setFiles(img)
  //     })
  //     .catch((err) => {
  //       OnUpdateError(err.data.message);
  //     });
  // };


  const handleDeleteFile = async (url) => {
    let body = {
      url: url
    };
    toggleLoader();
    await axios.post(`/upload/delete_file`, body)
      .then((res) => {
        swal("Success", res?.data?.message, "success");
        setFiles(files.filter(ele => ele != url));
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => toggleLoader());
  };

  const handleClear = () => {
    setViewModel(false);
    setViewModel(false)
    setData({
      _id: "",
      project: "",
      state: "",
      city: "",
      siteName: "",
      siteType: "",
      location: "",
      latitude: "",
      longitude: "",
      siteCategory: "",
      subCategory: "",
      sitePopularity: "",
      ownership: "",
      conservation: "",
      graded: "",
      description: "",
      associatedCommunity: "",
      employeId: "",
      sietLogo: "",
    });
    setError({});
    setIsEdit(false);
    setFiles([]);
  };

  const _getSiteType = () => {
    setLoading(true)
    axios.post(`/siteType`, {})
      .then((res) => {
        if (res?.data?.data) {
          setSiteTypeList(res?.data?.data?.response);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };

  const _getProject = () => {
    setLoading(true)
    axios.post(`/project`, {})
      .then((res) => {
        if (res?.data?.data) {
          setProjectList(res?.data?.data?.response);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };

  const _getEmploye = async () => {
    setLoading(true)
    let body = {
      userType: [2]
    };
    await axios.post("/users", body)
      .then((res) => {
        if (res?.data?.data) setEmployeList(res?.data?.data?.response);
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };

  const _getStates = () => {
    toggleLoader();
    axios.post("/states", {
      country_id: 101, // 101 for india
    })
      .then((res) => {
        if (res?.data?.data) {
          setStates(res?.data?.data?.response);
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };

  const _getCities = () => {
    toggleLoader();
    axios
      .post("/cities", {
        state_id: [_getDefaultId(states, data?.state)],
        country_id: 101,
      })
      .then((res) => {
        if (res?.data?.data) {
          setCities(res?.data?.data?.response);
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };

  const _getCitiesForFilter = () => {
    toggleLoader();
    axios
      .post("/cities", {
        state_id: filterData?.state?.length > 0 ? filterData?.state.map((item) => item.id) : null,
        country_id: 101,
      })
      .then((res) => {
        if (res?.data?.data) {
          setCities(res?.data?.data?.response);
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };

  const _geSitePopularity = () => {
    toggleLoader();
    axios
      .post("/sitePopularity", {})
      .then((res) => {
        if (res?.data?.data) {
          setSitePopularityList(res?.data?.data?.response);
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };

  const _getSiteCategory = () => {
    toggleLoader();
    axios
      .post("/siteCategory", {})
      .then((res) => {
        if (res?.data?.data) {
          setSiteCategoryList(res?.data?.data?.response);
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };


  const _getOwnership = () => {
    toggleLoader();
    axios
      .post("/ownership", {})
      .then((res) => {
        if (res?.data?.data) {
          setOwnershipList(res?.data?.data?.response);
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };

  const _getTrailName = () => {
    setLoading(true)
    axios.post(`/trailNumber`, {})
      .then((res) => {
        if (res?.data?.data) {
          setTrailNameList(res?.data?.data?.response);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };

  const _getSite = async () => {
    setLoading(true)
    let body = {
      limit: rowsPerPage,
      page: page,
      search: search || "",
      status: tabValue
    };
    await axios.post(`/site`, body)
      .then((res) => {
        if (res?.data?.data) {
          setSiteData(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };

  const handelEdit = (row) => {
    setIsEdit(true); setModel(true);
    setLoading(true)
    axios
      .get(`site/by_id/${row?._id}`)
      .then((res) => {
        if (res?.data?.data) {
          const siteData = res?.data?.data
          console.log('siteData.employeDetails?.name', siteData.employeDetails?.name)
          setData({
            _id: row?._id,
            project: siteData?.projectsDetails?.projectName,
            state: siteData?.statesDetails?.name,
            city: siteData?.cityDetails?.name,
            siteName: siteData.siteName,
            siteType: siteData?.siteTypesDetails?.siteType,
            location: siteData.location,
            latitude: siteData.latitude,
            longitude: siteData.longitude,
            siteCategory: siteData.siteCategoryDetails?.siteCategory,
            subCategory: siteData.subCategory,
            sitePopularity: siteData.sitePopularityDetails?.sitePopularity,
            ownership: siteData.ownershipDetails?.ownership,
            conservation: siteData.conservation,
            graded: siteData.graded,
            description: siteData.description,
            associatedCommunity: siteData.associatedCommunity,
            employeId: siteData.employeDetails?.name,
            sietLogo: siteData.sietLogo,
          });
          setFiles(siteData.siteImages)
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      })
      .finally(() => setLoading(false));
  };

  const handelView = (row) => {
    setViewModel(true)
    setLoading(true)
    axios
      .get(`site/by_id/${row?._id}`)
      .then((res) => {
        if (res?.data?.data) {
          const siteData = res?.data?.data
          setData({
            project: siteData?.projectsDetails?.projectName,
            state: siteData?.statesDetails?.name,
            city: siteData?.cityDetails?.name,
            siteName: siteData.siteName,
            siteType: siteData?.siteTypesDetails?.siteType,
            location: siteData.location,
            latitude: siteData.latitude,
            longitude: siteData.longitude,
            siteCategory: siteData.siteCategoryDetails?.siteCategory,
            subCategory: siteData.subCategory,
            sitePopularity: siteData.sitePopularityDetails?.sitePopularity,
            ownership: siteData.ownershipDetails?.ownership,
            conservation: siteData.conservation,
            graded: siteData.graded,
            description: siteData.description,
            associatedCommunity: siteData.associatedCommunity,
            employeId: siteData.employeDetails?.name,
            sietLogo: siteData.sietLogo,
          });
          setFiles(siteData.siteImages)
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      })
      .finally(() => setLoading(false));
  };


  const _approvedRejecteSite = async (id, status) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: "error",
      html: `Are you sure you want to ${(status === 1) ? "approve" : "rejecte"} site?`,
      showCancelButton: true,
      confirmButtonColor: "#0492c2",
      iconColor: lightTheme.palette.bgLightExtraSuccess.main,
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      const body = {
        id: id,
        status: status
      }
      if (result.isConfirmed) {
        toggleLoader();
        axios.post(`site/approve_reject`, body)
          .then((res) => {
            if (res?.data?.data) {
              swal("Success", res?.data?.message, "success");
              _getSite();
            }
          })
          .catch((err) => {
            OnUpdateError(err.data.message);
          }).finally(() => {
            toggleLoader();
          });
      }
    });
  }

  const _restoreSite = async (id) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: "error",
      html: `Are you sure you want to restore site?`,
      showCancelButton: true,
      confirmButtonColor: "#0492c2",
      iconColor: lightTheme.palette.bgLightExtraSuccess.main,
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      if (result.isConfirmed) {
        toggleLoader();
        axios.get(`site/restore/${id}`)
          .then((res) => {
            if (res?.data?.data) {
              swal("Success", res?.data?.message, "success");
              _getSite();
            }
          })
          .catch((err) => {
            OnUpdateError(err.data.message);
          }).finally(() => {
            toggleLoader();
          });
      }
    });
  }


  const _deleteSite = async (id) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: "error",
      html: "Are you sure you want to Delete Site",
      showCancelButton: true,
      confirmButtonColor: "#0492c2",
      iconColor: "#ff0000",
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      if (result.isConfirmed) {
        toggleLoader();
        axios.delete(`site/delete/${id}`)
          .then((res) => {
            if (res?.data?.data) {
              swal("Success", res?.data?.message, "success");
              _getSite();
            }
          })
          .catch((err) => {
            OnUpdateError(err.data.message);
          }).finally(() => {
            toggleLoader();
          });
      }
    });
  }

  const handelSendForApprovel = async (id) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: "error",
      html: "Are you sure you want to send site for approval",
      showCancelButton: true,
      confirmButtonColor: "#0492c2",
      iconColor: "#ff0000",
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      if (result.isConfirmed) {
        toggleLoader();
        axios.get(`site/sendForApproval/${id}`)
          .then((res) => {
            if (res?.data?.data) {
              swal("Success", res?.data?.message, "success");
              _getSite();
            }
          })
          .catch((err) => {
            OnUpdateError(err.data.message);
          }).finally(() => {
            toggleLoader();
          });
      }
    });
  }

  const _addUpdateNewForm = () => {
    if (handleValidation()) {
      toggleLoader();
      const body = {
        ...data,
        project: projectList?.find(ele => ele.projectName === data?.project)?._id,
        state: states?.find(ele => ele.name === data?.state)?.id,
        city: cities?.find(ele => ele.name === data?.city)?.id,
        siteType: siteTypeList?.find(ele => ele.siteType === data?.siteType)?._id,
        siteCategory: siteCategoryList?.find(ele => ele.siteCategory === data?.siteCategory)?._id,
        sitePopularity: sitePopularityList?.find(ele => ele.sitePopularity === data?.sitePopularity)?._id,
        ownership: ownershipList?.find(ele => ele.ownership === data?.ownership)?._id,
        employeId: employeList?.find(ele => ele.name === data?.employeId)?._id,
        siteImages: files,
      }
      if (!data?._id) {
        delete body._id
      }
      axios.post(`site/${data?._id ? "update" : "create"}`, body)
        .then((res) => {
          if (res?.data?.data) {
            swal("Success", res?.data?.message, "success");
            handleClear();
            setIsEdit()
            _getSite();
            setModel(false)
          }
          toggleLoader();
        })
        .catch((err) => {
          toggleLoader();
          OnUpdateError(err.data.message);
        });
    }
  };

  const _handelFilterData = () => {
    toggleLoader();
    const body = {
      limit: rowsPerPage,
      page: page,
      search: search || "",
      status: tabValue,
      project: filterData?.projectName?.length > 0 ? filterData?.projectName.map((item) => item._id) : null,
      siteType: filterData?.siteType?.length > 0 ? filterData?.siteType.map((item) => item._id) : null,
      siteCategory: filterData?.siteCategory?.length > 0 ? filterData?.siteCategory.map((item) => item._id) : null,
      sitePopularity: filterData?.sitePopularity?.length > 0 ? filterData?.sitePopularity.map((item) => item._id) : null,
      ownership: filterData?.ownership?.length > 0 ? filterData?.ownership.map((item) => item._id) : null,
      trailName: filterData?.trailName?.length > 0 ? filterData?.trailName.map((item) => item._id) : null,
      state: filterData?.state?.length > 0 ? filterData?.state.map((item) => item.id) : null,
      city: filterData?.city?.length > 0 ? filterData?.city.map((item) => item.id) : null,
    }
    axios.post(`site`, body)
      .then((res) => {
        if (res?.data?.data) {
          setSiteData(res?.data?.data);
          setIsFilter(false)
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };

  const _handelClearFilter = () => {
    toggleLoader();
    const body = {
      limit: rowsPerPage,
      page: page,
      status: tabValue,
    }
    axios.post(`site`, body)
      .then((res) => {
        if (res?.data?.data) {
          setFilterData(null)
          setSiteData(res?.data?.data);
          setIsFilter(false)
          setFilterVisible(false)
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };

  const getExtension = (filename) => {
    return filename.split('.').pop().toLowerCase();
  };

  const handelFindDocumentFile = (data) => {
    const docFile = data.filter(ele => doumentsExtensions.includes(getExtension(ele)))?.reverse()
    return <a href={`${Image_BASE_URL}${docFile?.[0]}`} target="_blank" rel="noopener noreferrer">{docFile.length > 0 ? "Download File" : ""}</a>

  }

  const handleImageUpload = async (val) => {
    const formData = new FormData();
    formData.append("image", val);
    toggleLoader();
    axios
      .post("/upload/image/attachment", formData)
      .then((res) => {
        if (res?.data?.data) {
          setData((prevData) => ({
            ...prevData,
            ["sietLogo"]: res?.data?.data?.image,
          }));
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };

  const _downloadSite = async () => {
    const body = {
      status: tabValue,
      project: filterData?.projectName?.length > 0 ? filterData?.projectName.map((item) => item._id) : null,
      siteType: filterData?.siteType?.length > 0 ? filterData?.siteType.map((item) => item._id) : null,
      siteCategory: filterData?.siteCategory?.length > 0 ? filterData?.siteCategory.map((item) => item._id) : null,
      sitePopularity: filterData?.sitePopularity?.length > 0 ? filterData?.sitePopularity.map((item) => item._id) : null,
      ownership: filterData?.ownership?.length > 0 ? filterData?.ownership.map((item) => item._id) : null,
      trailName: filterData?.trailName?.length > 0 ? filterData?.trailName.map((item) => item._id) : null,
      state: filterData?.state?.length > 0 ? filterData?.state.map((item) => item.id) : null,
      city: filterData?.city?.length > 0 ? filterData?.city.map((item) => item.id) : null,
    }
    await axios.post(`site/dwonload`, body, { responseType: "blob" })
      .then((res) => {
        if (res?.data) {
          downloadFileFromBlob(res?.data, "site_details.xlsx")
        }
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };

  useEffect(() => {
    if (filterData && filterData != null) {
      setFilterVisible(true)
    }
  }, [filterData])

  useEffect(() => {
    if (files.length > 0) {
      setFilterImageData({
        ...filterImageData,
        imageData: files.filter(ele => imageExtensions.includes(getExtension(ele))),
        pdfData: files.filter(ele => pdfExtensions.includes(getExtension(ele))),
        videoData: files.filter(ele => videoExtensions.includes(getExtension(ele))),
        documentData: files.filter(ele => doumentsExtensions.includes(getExtension(ele))),
        excelData: files.filter(ele => excelExtensions.includes(getExtension(ele))),
        audioData: files.filter(ele => audioExtensions.includes(getExtension(ele)))

      })
    }
  }, [files])


  useEffect(() => {
    _getProject();
    _getStates()
    _getSiteType();
    _getSiteCategory();
    _geSitePopularity();
    _getOwnership()
    _getEmploye();
    _getTrailName()
  }, [])

  useEffect(() => {
    if (states && data?.state) {
      _getCities();
    }
  }, [states, data?.state]);

  useEffect(() => {
    if (filterData && filterData?.state && filterData?.state?.length > 0) {
      _getCitiesForFilter();
    }
  }, [filterData, filterData?.state]);

  useEffect(() => {
    if (user?.userType === Roles?.Employe) {
      setData({ ...data, employeId: user?.name })
    }
  }, [user])

  useEffect(() => {
    _getSite();
  }, [page, rowsPerPage, search, tabValue]);

  useEffect(() => {
    const menu = menuList?.find((e) => e?.path === pathname);
    if (menu) {
      const menuPermissions = menu.permissions;
      setPermissions({
        view: menuPermissions.includes(permissionStatus.view) ? true : false,
        create: menuPermissions.includes(permissionStatus.create) ? true : false,
        update: menuPermissions.includes(permissionStatus.update) ? true : false,
        delete: menuPermissions.includes(permissionStatus.delete) ? true : false,
      });
    }
  }, [menuList, location]);

  return (
    <>
      {<PaperContainer elevation={0} square={false}>
        <Grid container spacing={0} xs={12} md={12} sm={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box sx={{ display: "flex", justifyContent: { xs: "end", sm: "end", md: "end", lg: "end" }, padding: "10px", gap: 1, flexWrap: "wrap", }}>
              <CommonButton text={permissions?.create ? "Add New Site" : ""} onClick={() => { handleClear(); setModel(true); }} />
            </Box>
          </Grid>
        </Grid>
      </PaperContainer>}

      <PaperContainer elevation={0} square={false}>

        {viewModel &&
          <Grid item xs={12}>
            <TableHeading title={`Site details`} handleBack={() => { handleClear() }} removeSearchField={true} />
            <ViewSite
              data={data}
              filterImageData={filterImageData}
            />
          </Grid>
        }

        {model &&
          <Grid item xs={12}>
            <TableHeading title={`${isEdit ? "Update" : "Add New"} Site`} handleBack={() => { setModel(false); handleClear(); }} removeSearchField={true} />
            <AddSite
              data={data}
              setData={setData}
              error={error}
              handleChange={handleChange}
              isEdit={isEdit}
              onSubmit={_addUpdateNewForm}
              handleDeleteFile={handleDeleteFile}
              handleUpload={handleUpload}
              siteTypeList={siteTypeList}
              files={files}
              setFiles={setFiles}
              addAudioFile={addAudioFile}
              deleteFiled={deleteFiled}
              employeList={employeList}
              setViewModel={setViewModel}
              projectList={projectList}
              states={states}
              cities={cities}
              siteCategoryList={siteCategoryList}
              sitePopularityList={sitePopularityList}
              ownershipList={ownershipList}
              handleImageUpload={handleImageUpload}
            />
          </Grid>
        }

        {(!model && !viewModel) && (
          <>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12}>
                <TableHeading
                  title={"Site list"}
                  buttonText={""}
                  onClick={() => setModel(true)}
                  handleSearch={(value) => { setPage(1); setSearch(value); }}
                  children={
                    <>
                      <Fab size="small" color="primary" aria-label="add" onClick={() => { setIsFilter(true) }}>
                        <Badge variant="dot" color="warning" max={-2} invisible={filterVisible ? false : true}>
                          <TuneIcon fontSize="small" />
                        </Badge>
                      </Fab>
                      <Fab size="small" color="primary" aria-label="add" onClick={() => _downloadSite()}>
                        <DownloadIcon fontSize="small" />
                      </Fab>
                    </>
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="site type tabs">
                  <Tab label="Approved Site" />
                  <Tab label="Rejected Site" />
                </Tabs>
                <TableContainer>
                  {!loading ? (
                    <Table sx={{ minWidth: 600 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell className={classes.paddedRow}>No.</StyledTableCell>
                          <StyledTableCell>Site profile</StyledTableCell>
                          <StyledTableCell>Project name</StyledTableCell>
                          <StyledTableCell>Site name</StyledTableCell>
                          <StyledTableCell>State name</StyledTableCell>
                          <StyledTableCell>Site type</StyledTableCell>
                          <StyledTableCell>Description</StyledTableCell>
                          <StyledTableCell>Associated community</StyledTableCell>
                          <StyledTableCell align="center">Douments</StyledTableCell>
                          <StyledTableCell align="center" >Approve/Reject</StyledTableCell>
                          <StyledTableCell align="center" >Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {siteData?.response?.length > 0 ? siteData?.response?.map((row, index) => {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell className={classes.paddedRow}>{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                              <StyledTableCell><a href={`${Image_BASE_URL}${row?.sietLogo}`} target="_blank" rel="noopener noreferrer" key={index}>
                                <CardMedia
                                  component="img"
                                  sx={{ height: 50, width: 60, cursor: 'pointer', borderRadius: "5px" }}
                                  image={`${Image_BASE_URL}${row?.sietLogo}`}
                                  alt={`media-${index}`}
                                />
                              </a></StyledTableCell>
                              <StyledTableCell component="th" scope="row" >{row?.projectsDetails?.projectName}</StyledTableCell>
                              <StyledTableCell component="th" scope="row">{row?.siteName}</StyledTableCell>
                              <StyledTableCell component="th" scope="row">{row?.statesDetails?.name}</StyledTableCell>
                              <StyledTableCell component="th" scope="row" >{row?.siteTypesDetails?.siteType}</StyledTableCell>
                              <StyledTableCell><ReadMore text={row?.description} limit={10} /></StyledTableCell>
                              <StyledTableCell><ReadMore text={row?.associatedCommunity} limit={10} /></StyledTableCell>
                              <StyledTableCell align="center">{handelFindDocumentFile(row?.siteImages)}</StyledTableCell>
                              {tabValue === 0 ? <StyledTableCell >
                                {(user?.userType !== Roles?.Employe && row?.status === siteStatus?.approvalPending) || (user?.userType === Roles?.SuperAdmin && row?.status === siteStatus?.approvedByAdmin) ?
                                  <Box display={"flex"} gap={1} justifyContent={"center"}>
                                    <Assets className={classes.viewBox} src={"/assets/icons/right.svg"} absolutePath={true} onClick={() => _approvedRejecteSite(row?._id, 1)} />
                                    <Assets className={classes.deleteBox} src={"/assets/icons/close.svg"} absolutePath={true} onClick={() => _approvedRejecteSite(row?._id, 2)} />
                                  </Box> :
                                  <Box display={"flex"} justifyContent={"center"}>
                                    {row?.status === siteStatus?.sendForApproval ? <CommonButton width={'160px'} text={`Send for approval`} type="submit" onClick={() => handelSendForApprovel(row?._id)} /> : <TextLabel fontSize={"12px"} color={"white"} fontWeight={"400"} title={siteStatusConfig?.find((e) => e?.statusId === row.status)?.statusName} textAlign={"center"} style={{ backgroundColor: statusColors[row?.status], borderRadius: "20px", width: "130px", padding: "5px 5px", }} />}
                                  </Box>}
                              </StyledTableCell> :
                                <StyledTableCell>
                                  <Box display={"flex"} justifyContent={"center"}>
                                    <TextLabel fontSize={"12px"} color={"white"} fontWeight={"400"} title={siteStatusConfig?.find((e) => e?.statusId === row.status)?.statusName} textAlign={"center"} style={{ backgroundColor: statusColors[row?.status], borderRadius: "20px", width: "130px", padding: "5px 5px", }} />
                                  </Box>
                                </StyledTableCell>
                              }

                              {tabValue === 0 ?
                                <StyledTableCell >
                                  {(permissions?.update || permissions?.delete) &&
                                    <Box display={"flex"} gap={1} justifyContent={"center"}>
                                      <Assets className={classes.viewBox} src={"/assets/icons/eye.svg"} absolutePath={true} onClick={() => handelView(row)} />
                                      {permissions?.update && (<Assets className={classes.writeBox} src={"/assets/icons/write.svg"} absolutePath={true} onClick={() => handelEdit(row)} />)}
                                      {permissions?.delete && (<Assets className={classes.deleteBox} src={"/assets/icons/delete.svg"} absolutePath={true} onClick={() => { _deleteSite(row?._id) }} />)}
                                    </Box>}
                                </StyledTableCell> :
                                <StyledTableCell >
                                  <Box display={"flex"} gap={1} justifyContent={"center"}>
                                    <Assets className={classes.writeBox} src={"/assets/icons/restore.svg"} absolutePath={true} onClick={() => { _restoreSite(row?._id) }} />
                                  </Box>
                                </StyledTableCell>}
                            </StyledTableRow>
                          );
                        }) :
                          <TableRow>
                            <TableCell colSpan={12}> <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} /></TableCell>
                          </TableRow>
                        }
                      </TableBody>
                    </Table>
                  ) : (
                    <WidgetLoader />
                  )}
                </TableContainer>
              </Grid>

              {siteData?.count > 0 &&
                <Grid item xs={12}>
                  <CustomPagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    count={Math.ceil(siteData?.count / rowsPerPage)}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage} />

                </Grid>}
            </Grid>
          </>
        )}


      </PaperContainer>

      {/* {addSiteOnTrail && (
        <CommonModal
          open={model}
          onClose={handleClear}
          title={`${isEdit ? "Update" : "Add New"} Site`}
          maxWidth={'md'}
          content={
            <AddSite
              data={data}
              setData={setData}
              error={error}
              handleChange={handleChange}
              isEdit={isEdit}
              onSubmit={_addUpdateNewForm}
              handleDeleteFile={handleDeleteFile}
              handleUpload={handleUpload}
              siteTypeList={siteTypeList}
              files={files}
              setFiles={setFiles}
              addAudioFile={addAudioFile}
              deleteFiled={deleteFiled}
              setSelectedSiteType={setSelectedSiteType}
              selectedSiteType={selectedSiteType}
              employeList={employeList}
              selectedEmploye={selectedEmploye}
              setSelectedEmploye={setSelectedEmploye}
            />
          }
        />
      )} */}

      {/* {viewModel && (
        <CommonModal
          open={viewModel}
          onClose={handleClear}
          title={`Site details`}
          maxWidth={'lg'}
          content={
            <ViewSite
              filterImageData={filterImageData}

            />
          }
        />
      )} */}

      {isFilter && <SideDrawer
        drawerWidth={"25%"}
        anchor={"right"}
        open={isFilter}
        content={
          <SitFiltercontent
            filterData={filterData}
            setFilterData={setFilterData}
            projectList={projectList}
            siteTypeList={siteTypeList}
            siteCategoryList={siteCategoryList}
            sitePopularityList={sitePopularityList}
            ownershipList={ownershipList}
            trailNameList={trailNameList}
            stateList={states}
            cityList={cities}
            handelFilterData={_handelFilterData}
            handelClearFilter={_handelClearFilter}
          />
        }
        onClose={() => { setIsFilter(false); }}
        onSave={() => { '' }}
        header="All site filter"
      />}


    </>
  )
}

export default Site