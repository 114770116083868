import React from 'react'
import { Box, Grid, InputLabel, TextField } from "@mui/material";
import { useTheme } from '@mui/styles';
import { makeStyles } from "tss-react/mui";
import TextLabel from '../Common/Fields/TextLabel';
import CommonButton from '../Common/Button/CommonButton';
import { lightTheme } from '../../theme';
import AutoCompleteMultiSelect from '../Common/AutoCompleteMultiSelect';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import { Roles } from '../../Utils/enum';
import { useAppContext } from '../../Context/context';

const useStyles = makeStyles()((theme) => {
    return {
        dateBox: {
            background: theme?.palette?.bgWhite?.main,
            borderRadius: '8px',
            // minWidth: "50px",
            "& .MuiOutlinedInput-root": {
                borderRadius: "5px !important",
            },
            "& .MuiOutlinedInput-input": {
                padding: "12.5px 14px",
                fontSize: "14px !important",
                height: "18px",
            },
            "&:hover": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.backgroundDefaultColor?.main} !important`,
                borderRadius: "8px",
            },
            ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
                borderWidth: "1px !important",
            },
            "& .MuiInputBase-input.Mui-disabled": {
                backgroundColor: "#e0e0e0",
                borderRadius: '8px',
            }
        },
        customLabel: {
            "& .MuiTypography-root": {
                fontSize: "15px",
                color: "#151D48",
            },
        },
        deleteBox: {
            borderRadius: "6px",
            padding: "6px",
            color: lightTheme.palette.bgLightRed.main,
            backgroundColor: lightTheme.palette.bgLightExtraRed.main,
            cursor: "pointer",
            height: '26px',
        },
    };
});
const DwonloadEmploye = ({ dwonloadEmployeId, setDwonloadEmployeId, _downloadEmployeAttendance, employeList, dwonloadFilter, setDwonloadFilter, dwonloadFilterError }) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const { user } = useAppContext();
    return (
        <Box>
            <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
                {user?.userType !== Roles?.Employe && <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AutoCompleteMultiSelect
                        fullWidth
                        text="Employee"
                        placeholder={"Select employee"}
                        handleChange={(e, newValue) => { setDwonloadEmployeId(newValue) }}
                        options={employeList.filter(ele => !dwonloadEmployeId?.some(select => select._id === ele._id))}
                        name="employe"
                        getOptionLabel={(option) => option?.name}
                        defaultValue={dwonloadEmployeId || {}}
                        mappingLabel='name'
                    />
                </Grid>}
                
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box mt={0.5} display="flex" flexDirection={"row"}                                            >
                            <InputLabel sx={{ marginRight: "3px", fontWeight: 400, fontSize: "12px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>State date </InputLabel>
                        </Box>
                        <DesktopDatePicker
                            className={classes.dateBox}
                            inputFormat="MM/DD/YYYY"
                            value={dayjs(dwonloadFilter.startDate)}
                            onChange={(newValue) => { setDwonloadFilter({ ...dwonloadFilter, startDate: newValue }) }}
                            name='startDate'
                            renderInput={(params) => <TextField {...params} />}
                            disableFuture
                        />
                    </LocalizationProvider>
                    <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={!dwonloadFilter?.startDate ? dwonloadFilterError?.startDate : ""} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box mt={0.5} display="flex" flexDirection={"row"}                                            >
                            <InputLabel sx={{ marginRight: "3px", fontWeight: 400, fontSize: "12px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>End date</InputLabel>
                        </Box>
                        <DesktopDatePicker
                            className={classes.dateBox}
                            inputFormat="MM/DD/YYYY"
                            value={dayjs(dwonloadFilter?.endDate)}
                            onChange={(newValue) => { setDwonloadFilter({ ...dwonloadFilter, endDate: newValue }); }}
                            name='endDate'
                            renderInput={(params) => <TextField {...params} />}
                            disableFuture
                        />
                    </LocalizationProvider>
                    <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={!dwonloadFilter?.endDate ? dwonloadFilterError?.endDate : ""} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
                        <CommonButton
                            width={'160px'}
                            text={`Dwonload`}
                            type="submit"
                            onClick={_downloadEmployeAttendance}
                        />
                    </Box>
                </Grid>
            </Grid >
        </Box>
    )
}

export default DwonloadEmploye