import React, { useRef, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Header from "./Header";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import { useAppContext } from "../../Context/context";
import Assets from "./ImageContainer";
import DashboardIcon from "../Icons/dashboardIcon";
import { Divider, Tooltip, useTheme } from "@mui/material";
import ProfileIcon from "../Icons/profileIcon";
import BranchIcon from "../Icons/designationIcon";
import ClientIcon from "../Icons/clientIcon";
import SmsIcon from "../Icons/smsIcon";
import DiscountIcon from "../Icons/discountIcon";
import DolorIcon from "../Icons/dolorIcon";
import CalenderIcon from "../Icons/calenderIcon";
import ProfileTicIcon from "../Icons/profileTicIcon";
import InfoIcon from "../Icons/infoIcon";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import PortraitIcon from "../Icons/portraitIcon";
import NewFileIcon from "../Icons/newFile";
import MessageText from "../Icons/message-text";
import CalculatorIcon from "../Icons/calculatorIcon";
import DocumentTextIcon from "../Icons/documentTextIcon";
import { Roles } from "../../Utils/enum";
import DesignationIcon from "../Icons/designationIcon";
import SiteTypeIcon from "../Icons/siteType";
import ProjectIcon from "../Icons/projectIcon";
import SiteCategoryIcon from "../Icons/siteCategoryIcon";
import Ownership from "../Icons/Ownership";
import SiteIcon from "../Icons/siteIcon";
import SitePopularityIcon from "../Icons/sitePopularityIcon";
import TrailNumberIcon from "../Icons/trailNumberIcon";
const drawerWidth = 255;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `70px`,
  [theme.breakpoints.up("md")]: {
    width: `70px`,
  },
  [theme.breakpoints.down("md")]: {
    width: `0px`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  minHeight: '68px !important',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));



export default function SideBar(props) {
  const width = window.innerWidth;
  const location = useLocation();
  const sidebarRef = useRef(null);
  const { toggleSideBar, menuList, user } = useAppContext();
  const theme = useTheme()
  const [open, setOpen] = useState(true);


  const menuIconList = [
    {
      title: "Dashboard",
      icon: <DashboardIcon color={location?.pathname === "/" ? theme?.palette?.primary?.main : theme?.palette?.bgLightExtraLightGray?.main} />,
    },
    {
      title: "Create User",
      icon: <ProfileIcon color={location?.pathname === "/user" ? theme?.palette?.primary?.main : theme?.palette?.bgLightExtraLightGray?.main} />,
    },
    {
      title: "User List",
      icon: <ProfileIcon color={location?.pathname === "/user" ? theme?.palette?.primary?.main : theme?.palette?.bgLightExtraLightGray?.main} />,
    },
    {
      title: "Create Designation",
      icon: <DesignationIcon color={location?.pathname === "/designation" ? theme?.palette?.primary?.main : theme?.palette?.bgLightExtraLightGray?.main} />,
    },
    {
      title: "Create siteType",
      icon: <SiteTypeIcon color={location?.pathname === "/site-type" ? theme?.palette?.primary?.main : theme?.palette?.bgLightExtraLightGray?.main} />,
    },
    {
      title: "Create Project",
      icon: <ProjectIcon color={location?.pathname === "/project" ? theme?.palette?.primary?.main : theme?.palette?.bgLightExtraLightGray?.main} />,
    },
    {
      title: "Create site Category",
      icon: <SiteCategoryIcon color={location?.pathname === "/site-category" ? theme?.palette?.primary?.main : theme?.palette?.bgLightExtraLightGray?.main} />,
    },
    {
      title: "Create Site Popularity",
      icon: <SitePopularityIcon color={location?.pathname === "/site-popularity" ? theme?.palette?.primary?.main : theme?.palette?.bgLightExtraLightGray?.main} />,
    },
    {
      title: "Ownership",
      icon: <Ownership color={location?.pathname === "/ownership" ? theme?.palette?.primary?.main : theme?.palette?.bgLightExtraLightGray?.main} />,
    },
    {
      title: "Create Site",
      icon: <SiteIcon color={location?.pathname === "/site" ? theme?.palette?.primary?.main : theme?.palette?.bgLightExtraLightGray?.main} />,
    },
    {
      title: "Create Trail Number",
      icon: <NewFileIcon color={location?.pathname === "/trailNumber" ? theme?.palette?.primary?.main : theme?.palette?.bgLightExtraLightGray?.main} />,
    },
    {
      title: "Employe Attendance",
      icon: <ClientIcon color={location?.pathname === "/attendance" ? theme?.palette?.primary?.main : theme?.palette?.bgLightExtraLightGray?.main} />,
    },
    {
      title: "Create Trail Name",
      icon: <TrailNumberIcon color={location?.pathname === "/trail-number" ? theme?.palette?.primary?.main : theme?.palette?.bgLightExtraLightGray?.main} />,
    },

  ];

  const handleDrawerOpen = () => {
    setOpen(!open);
    toggleSideBar();
  };


  const getIcon = (page) => {
    const icon = menuIconList.find(e => e?.title === page)?.icon
    return icon
  }

  useEffect(() => {
    const handleResize = () => {
      setOpen(window.innerWidth >= theme.breakpoints.values.xl);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <>
      {location?.pathname === "/login" || location?.pathname === "/register" || location?.pathname === "/forgot-password" || location?.pathname === "/otp-verification" || location?.pathname === "/reset-password" || user?.userType === Roles.Feedback
        ? <>
          {props.children}
        </> :
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="fixed" open={open} style={{ boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.05)', }} >
            <Header open={open} sx={{ marginLeft: 0.8, }} onClick={handleDrawerOpen} handleDrawerOpen={handleDrawerOpen} />
          </AppBar>
          <Drawer variant="permanent" open={open} onClose={handleDrawerOpen} ref={sidebarRef} hideBackdrop>
            <DrawerHeader sx={{ background: "#fff", zIndex: 999999, display: "flex", justifyContent: "center", marginTop: '0px', borderBottom: `1px solid ${"#e9e9e9"}`, minHeight: '61px !important' }} >
              {open &&
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={10}>
                  <Assets style={{ cursor: "pointer", }} height={width < 991 ? "40px" : "48px"} src={"/assets/icons/logo.jpg"} absolutePath={true} />
                  <ArrowCircleLeftOutlinedIcon sx={{ color: theme.palette.primary.main, cursor: "pointer" }} onClick={handleDrawerOpen} />
                </Box>
              }
            </DrawerHeader>
            <List sx={{
              padding: { xs: 1, sm: 1, md: theme => theme.spacing(3), lg: theme => theme.spacing(3), marginTop: 3 },
              overflowX: "hidden",
              "::-webkit-scrollbar": { width: "0.5px" },
              "::-webkit-scrollbar-thumb": { backgroundColor: "transparent" }
            }}>
              {menuList.map((item, index) => {
                return (
                  <Link
                    to={item.path}
                    className={item?.activeLinks?.includes(location.pathname.split("/")?.[1]) ? "active" : "nav-link width-100"}
                    onClick={width > 891 ? () => { setOpen(false) } : () => handleDrawerOpen()}
                  >
                    <ListItem key={index} disablePadding sx={{ display: "block", marginBottom: 0.2, }}>
                      <ListItemButton
                        sx={{
                          minHeight: 18,
                          justifyContent: open ? "initial" : "center",
                          // px: 2,
                          marginBottom: 1,
                          borderLeft: item?.activeLinks?.includes(location.pathname.split("/")?.[1]) ? "5px solid #5D5FEF" : "",
                          // borderRadius: '10px',
                          "&:hover": {
                            // borderLeft: "3px solid #8f8f8f"
                            // ".MuiListItemText-root .MuiTypography-root": { color: "#8f8f8f", },
                          },
                        }}
                      >
                        <Tooltip title={item?.page} placement="right-start">
                          <ListItemIcon sx={{ minWidth: 0, color: "#424448", mr: open ? 1 : "auto", justifyContent: "center", }}>
                            {getIcon(item?.page)}
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                          style={{ whiteSpace: "nowrap", }}
                          primary={item?.page}
                          sx={{
                            color: item?.activeLinks?.includes(location.pathname.split("/")?.[1]) ? theme.palette.primary.main : "#737791",
                            opacity: open ? 1 : 0,
                            "& .MuiTypography-root": {
                              fontSize: '14px',
                              fontWeight: item?.activeLinks?.includes(location.pathname.split("/")?.[1]) ? 'bold' : "normal",
                            }
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )
              })}
            </List>
          </Drawer>
          <Box sx={{ marginTop: 7, width: "100%", marginBottom: "34px", [theme.breakpoints.down("xs")]: { marginBottom: "44px" }, }}>
            <Box style={{ width: "100%" }}>
              {props.children}
            </Box>
          </Box>
          <Footer open={open} />
        </Box >}
    </>
  );
}
