import React, { useState } from 'react';

const ReadMore = ({ text, limit }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const words = text.split(' ');
  const displayedText = isReadMore ? words.slice(0, limit).join(' ') : text;
  
  return (
    <span>
      {displayedText}
      {words.length > limit && (
        <span onClick={toggleReadMore} style={{ color: 'blue', cursor: 'pointer', fontSize:"12px" }}>
          {isReadMore ? '... Read More' : ' Show Less'}
        </span>
      )}
    </span>
  );
};

export default ReadMore;
