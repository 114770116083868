import React from 'react'

const SiteCategoryIcon = () => {
  return (
   
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#717791" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 2V22" stroke="#717791" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 9.5H12" stroke="#717791" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 14.5H22" stroke="#717791" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}

export default SiteCategoryIcon
