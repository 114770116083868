import React from 'react'

const SitePopularityIcon = () => {
  return (
  
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.26 18.9V7.1C19.26 5.6 18.62 5 17.03 5H15.99C14.4 5 13.76 5.6 13.76 7.1V18.9" stroke="#717791" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.26001 18.9V12.1C5.26001 10.6 5.90001 10 7.49001 10H8.53001C10.12 10 10.76 10.6 10.76 12.1V18.9" stroke="#717791" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 19H22" stroke="#717791" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}

export default SitePopularityIcon
