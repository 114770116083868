import React from 'react'
import { Box, TablePagination, Pagination } from "@mui/material";

const CustomPagination = ({ count, page, onPageChange, rowsPerPage, onRowsPerPageChange, rowsPerPageOptions }) => {
    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} flexWrap={'wrap'}>
            <TablePagination
                component="div"
                rowsPerPageOptions={rowsPerPageOptions}
                count={count}
                page={page}
                onPageChange={onPageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={onRowsPerPageChange}
                nextIconButtonProps={{ style: { display: "none" } }}
                backIconButtonProps={{ style: { display: "none" } }}
                labelDisplayedRows={(from = page) => (``)}
            // rowSpan={5}

            />
            <Pagination variant="outlined" count={count} page={page} onChange={onPageChange} size='small' color="primary" shape="rounded" sx={{ marginRight: '20px !important' }} />
        </Box>
    )
}

export default CustomPagination