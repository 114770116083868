import React from 'react'
import { Box, Grid, } from "@mui/material";
import TextLabel from '../Common/Fields/TextLabel';
import CommonTextField from '../Common/Fields/TextField';
import CommonButton from '../Common/Button/CommonButton';
import AutoCompleteMultiSelect from '../Common/AutoCompleteMultiSelect';

const AddTrailNumber = ({ data, setData, error, handleChange, isEdit, onSubmit, siteList }) => {
    return (
        <Box>
            <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CommonTextField
                        fontWeight={400}
                        text={'Trail name'}
                        placeholder={"Enter trail name"}
                        type='text'
                        name='trailNumber'
                        value={data?.trailNumber}
                        onChange={(e) => handleChange(e, false)}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.trailNumber ? error?.trailNumber : ""} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AutoCompleteMultiSelect
                        fullWidth
                        text="Site"
                        placeholder={"Select site"}
                        handleChange={(e, newValue) => { setData({ ...data, site: newValue }) }}
                        options={(Array.isArray(siteList) ? siteList : []).filter(ele => !(Array.isArray(data?.site) ? data.site : []).some(select => select._id === ele._id))}
                        name="site"
                        getOptionLabel={(option) => option?.siteName || ''}
                        defaultValue={Array.isArray(data?.site) ? data.site : []}
                        mappingLabel='siteName'
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={Array.isArray(data?.site) && data.site.length == 0 ? error?.site : ""} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CommonTextField
                        fontWeight={400}
                        text={'Trail number description'}
                        placeholder={"Enter trail number Description"}
                        type='text'
                        name='description'
                        value={data?.description}
                        onChange={(e) => handleChange(e, false)}
                        multiline={true}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.description ? error?.description : ""} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
                        <CommonButton
                            width={'280px'}
                            text={`${isEdit ? "Update" : "Create"} Trail number`}
                            type="submit"
                            onClick={onSubmit}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AddTrailNumber