export const globalAmountConfig = (value) => {
    const inputValue = value;

    let numericValue = inputValue?.toString().replace(/[^0-9\.]/g, "");
    const formattedValue = numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/(\.\d{0})\d+$/g, "$1").replace(".", "");

    return formattedValue;
};


export const convertToNumber = (val) => {
    return Number(val?.toString()?.replace(/,/g, "")?.split(","))
}