import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Table, TableRow, TableHead, Box, Grid, TableContainer, Container, Fab, Badge, CardMedia } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Assets from "../../Components/Common/ImageContainer";
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import { lightTheme } from "../../theme";
import { useAppContext } from "../../Context/context";
import axios, { Image_BASE_URL } from "../../APiSetUp/axios";
import CommonModal from "../../Components/Common/CommonModel";
import { useLocation } from "react-router-dom";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WidgetLoader from "../../Components/Common/widgetLoader";
import CustomPagination from "../../Components/Common/CustomPagination";
import TuneIcon from '@mui/icons-material/Tune';
import SideDrawer from "../Common/SideDrawer";
import SitFiltercontent from "../Common/SitFiltercontent";
import ReadMore from "../Common/ReadMore";
import ViewSiteModel from "../SiteModel/viewSiteModel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        fontSize: 14,
        color: theme.palette.primary.main,
        fontFamily: "Poppins",
        whiteSpace: "nowrap",
        padding: 8
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Poppins",
        fontWeight: 500,
        padding: "8px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const useStyles = makeStyles()((theme) => {
    return {
        paddedRow: {
            padding: "15px 10px",
        },
        writeBox: {
            borderRadius: "6px",
            padding: "6px",
            backgroundColor: lightTheme.palette.bgLightExtraPrimary.main,
            color: lightTheme.palette.primary.main,
            cursor: "pointer",
            height: '26px'
        },
        viewBox: {
            borderRadius: "6px",
            padding: "6px",
            color: lightTheme.palette.bgLightSuccess.main,
            backgroundColor: lightTheme.palette.bgLightExtraSuccess.main,
            cursor: "pointer",
            height: '26px'
        },
        deleteBox: {
            borderRadius: "6px",
            padding: "6px",
            color: lightTheme.palette.bgLightRed.main,
            backgroundColor: lightTheme.palette.bgLightExtraRed.main,
            cursor: "pointer",
            height: '26px'
        },
    };
});


const SiteTable = () => {
    const { classes } = useStyles();
    const { OnUpdateError, toggleLoader } = useAppContext();

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const videoExtensions = ['mp4', 'avi', 'mov'];
    const pdfExtensions = ['pdf'];
    const doumentsExtensions = ['doc', 'docx',];
    const excelExtensions = ['xls', 'xlsx'];
    const audioExtensions = ['mp3', 'wav'];


    //States
    const [siteData, setSiteData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false)
    const [viewModel, setViewModel] = useState(false);
    const [siteImages, setSiteImages] = useState([]);
    const [rowData, setRowData] = useState([]);

    const [siteTypeList, setSiteTypeList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [sitePopularityList, setSitePopularityList] = useState([]);
    const [ownershipList, setOwnershipList] = useState([]);
    const [trailNameList, setTrailNameList] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [siteCategoryList, setSiteCategoryList] = useState([]);

    const [filterData, setFilterData] = useState(null);
    const [isFilter, setIsFilter] = useState(false);
    const [filterVisible, setFilterVisible] = useState(false);

    const [filterImageData, setFilterImageData] = useState({
        imageData: [],
        pdfData: [],
        documentData: [],
        excelData: [],
        videoData: [],
        audioData: []
    })

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handleClear = () => {
        setViewModel(false);
    };

    const _getSite = async () => {
        setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: search || "",
            status: 0
        };
        await axios.post(`/site`, body)
            .then((res) => {
                if (res?.data?.data) {
                    setSiteData(res?.data?.data);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => setLoading(false));
    };

    const _getProject = () => {
        setLoading(true)
        axios.post(`/project`, {})
            .then((res) => {
                if (res?.data?.data) {
                    setProjectList(res?.data?.data?.response);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => setLoading(false));
    };

    const _getSiteType = () => {
        setLoading(true)
        axios.post(`/siteType`, {})
            .then((res) => {
                if (res?.data?.data) {
                    setSiteTypeList(res?.data?.data?.response);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => setLoading(false));
    };


    const _geSitePopularity = () => {
        toggleLoader();
        axios
            .post("/sitePopularity", {})
            .then((res) => {
                if (res?.data?.data) {
                    setSitePopularityList(res?.data?.data?.response);
                }
                toggleLoader();
            })
            .catch((err) => {
                toggleLoader();
                OnUpdateError(err.data.message);
            });
    };

    const _getSiteCategory = () => {
        toggleLoader();
        axios
            .post("/siteCategory", {})
            .then((res) => {
                if (res?.data?.data) {
                    setSiteCategoryList(res?.data?.data?.response);
                }
                toggleLoader();
            })
            .catch((err) => {
                toggleLoader();
                OnUpdateError(err.data.message);
            });
    };


    const _getOwnership = () => {
        toggleLoader();
        axios
            .post("/ownership", {})
            .then((res) => {
                if (res?.data?.data) {
                    setOwnershipList(res?.data?.data?.response);
                }
                toggleLoader();
            })
            .catch((err) => {
                toggleLoader();
                OnUpdateError(err.data.message);
            });
    };

    const _getTrailName = () => {
        setLoading(true)
        axios.post(`/trailNumber`, {})
            .then((res) => {
                if (res?.data?.data) {
                    setTrailNameList(res?.data?.data?.response);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => setLoading(false));
    };

    
  const _getStates = () => {
    toggleLoader();
    axios.post("/states", {
      country_id: 101, // 101 for india
    })
      .then((res) => {
        if (res?.data?.data) {
          setStates(res?.data?.data?.response);
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };


    const _getCitiesForFilter = () => {
        toggleLoader();
        axios
          .post("/cities", {
            state_id: filterData?.state?.length > 0 ? filterData?.state.map((item) => item.id) : null,
            country_id: 101,
          })
          .then((res) => {
            if (res?.data?.data) {
              setCities(res?.data?.data?.response);
            }
            toggleLoader();
          })
          .catch((err) => {
            toggleLoader();
            OnUpdateError(err.data.message);
          });
      };
    

    const _handelFilterData = () => {
        toggleLoader();
        const body = {
            limit: rowsPerPage,
            page: page,
            status: 0,
            search: search || "",
            project: filterData?.projectName?.length > 0 ? filterData?.projectName.map((item) => item._id) : null,
            siteType: filterData?.siteType?.length > 0 ? filterData?.siteType.map((item) => item._id) : null,
            siteCategory: filterData?.siteCategory?.length > 0 ? filterData?.siteCategory.map((item) => item._id) : null,
            sitePopularity: filterData?.sitePopularity?.length > 0 ? filterData?.sitePopularity.map((item) => item._id) : null,
            ownership: filterData?.ownership?.length > 0 ? filterData?.ownership.map((item) => item._id) : null,
            trailName: filterData?.trailName?.length > 0 ? filterData?.trailName.map((item) => item._id) : null,
            state: filterData?.state?.length > 0 ? filterData?.state.map((item) => item.id) : null,
            city: filterData?.city?.length > 0 ? filterData?.city.map((item) => item.id) : null,

        }
        axios.post(`site`, body)
            .then((res) => {
                if (res?.data?.data) {
                    setSiteData(res?.data?.data);
                    setIsFilter(false)
                }
                toggleLoader();
            })
            .catch((err) => {
                toggleLoader();
                OnUpdateError(err.data.message);
            });
    };

    const _handelClearFilter = () => {
        toggleLoader();
        const body = {
            limit: rowsPerPage,
            page: page,
            status: 0,
        }
        axios.post(`site`, body)
            .then((res) => {
                if (res?.data?.data) {
                    setFilterData(null)
                    setSiteData(res?.data?.data);
                    setIsFilter(false)
                    setFilterVisible(false)
                }
                toggleLoader();
            })
            .catch((err) => {
                toggleLoader();
                OnUpdateError(err.data.message);
            });
    };

    const handelView = (row) => {
        setViewModel(true);
        setLoading(true)
        axios
            .get(`site/by_id/${row?._id}`)
            .then((res) => {
                if (res?.data?.data) {
                    const siteData = res?.data?.data
                    setRowData({
                        project: siteData?.projectsDetails?.projectName,
                        state: siteData?.statesDetails?.name,
                        city: siteData?.cityDetails?.name,
                        siteName: siteData.siteName,
                        siteType: siteData?.siteTypesDetails?.siteType,
                        location: siteData.location,
                        latitude: siteData.latitude,
                        longitude: siteData.longitude,
                        siteCategory: siteData.siteCategoryDetails?.siteCategory,
                        subCategory: siteData.subCategory,
                        sitePopularity: siteData.sitePopularityDetails?.sitePopularity,
                        ownership: siteData.ownershipDetails?.ownership,
                        conservation: siteData.conservation,
                        graded: siteData.graded,
                        description: siteData.description,
                        associatedCommunity: siteData.associatedCommunity,
                        employeId: siteData.employeDetails?.name,
                        sietLogo: siteData.sietLogo,
                    });
                    setSiteImages(siteData.siteImages)
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            })
            .finally(() => setLoading(false));
    };

    const getExtension = (filename) => {
        return filename.split('.').pop().toLowerCase();
    };


    useEffect(() => {
        _getProject();
        _getSiteType();
        _getSiteCategory();
        _geSitePopularity();
        _getOwnership()
        _getTrailName()
        _getStates()
    }, []);

    useEffect(() => {
        if (filterData && filterData?.state && filterData?.state?.length > 0) {
          _getCitiesForFilter();
        }
      }, [filterData, filterData?.state]);
    

    useEffect(() => {
        if (siteImages.length > 0) {
            setFilterImageData({
                ...filterImageData,
                imageData: siteImages.filter(ele => imageExtensions.includes(getExtension(ele))),
                pdfData: siteImages.filter(ele => pdfExtensions.includes(getExtension(ele))),
                videoData: siteImages.filter(ele => videoExtensions.includes(getExtension(ele))),
                documentData: siteImages.filter(ele => doumentsExtensions.includes(getExtension(ele))),
                excelData: siteImages.filter(ele => excelExtensions.includes(getExtension(ele))),
                audioData: siteImages.filter(ele => audioExtensions.includes(getExtension(ele)))
            })
        }
    }, [siteImages])

    useEffect(() => {
        if (filterData && filterData != null) {
            setFilterVisible(true)
        }
    }, [filterData])

    useEffect(() => {
        _getSite();
    }, [page, rowsPerPage, search]);

    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <PaperContainer elevation={0} square={false}>
                    {/* {viewModel &&
                        <Grid item xs={12}>
                            <TableHeading title={`Site details`} handleBack={() => { handleClear() }} removeSearchField={true} />
                            <ViewSite
                                data={rowData}
                                filterImageData={siteImages}
                            />
                        </Grid>
                    } */}

                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid item xs={12}>
                            <TableHeading
                                title={"Site list"}
                                handleSearch={(value) => { setPage(1); setSearch(value); }}
                                children={
                                    <>
                                        <Fab size="small" color="primary" aria-label="add" onClick={() => { setIsFilter(true) }}>
                                            <Badge variant="dot" color="warning" max={-2} invisible={filterVisible ? false : true}>
                                                <TuneIcon fontSize="small" />
                                            </Badge>
                                        </Fab>
                                    </>
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableContainer>
                                {!loading ? (
                                    <Table sx={{ minWidth: 600 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className={classes.paddedRow}>No.</StyledTableCell>
                                                <StyledTableCell>Site profile</StyledTableCell>
                                                <StyledTableCell>Project name</StyledTableCell>
                                                <StyledTableCell>Site name</StyledTableCell>
                                                <StyledTableCell>State</StyledTableCell>
                                                <StyledTableCell>Site Type</StyledTableCell>
                                                <StyledTableCell>Description</StyledTableCell>
                                                <StyledTableCell>Associated Community</StyledTableCell>
                                                <StyledTableCell align="center" >Action</StyledTableCell>

                                            </TableRow>
                                        </TableHead>


                                        <TableBody>
                                            {siteData?.response?.length > 0 ? siteData?.response?.map((row, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell className={classes.paddedRow}>{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                                        <StyledTableCell><a href={`${Image_BASE_URL}${row?.sietLogo}`} target="_blank" rel="noopener noreferrer" key={index}>
                                                            <CardMedia
                                                                component="img"
                                                                sx={{ height: 50, width: 60, cursor: 'pointer', borderRadius: "5px" }}
                                                                image={`${Image_BASE_URL}${row?.sietLogo}`}
                                                                alt={`media-${index}`}
                                                            />
                                                        </a></StyledTableCell>
                                                        <StyledTableCell component="th" scope="row">{row?.projectsDetails?.projectName}</StyledTableCell>
                                                        <StyledTableCell component="th" scope="row">{row?.siteName}</StyledTableCell>
                                                        <StyledTableCell component="th" scope="row">{row?.statesDetails?.name}</StyledTableCell>
                                                        <StyledTableCell>{row?.siteTypesDetails?.siteType}</StyledTableCell>
                                                        <StyledTableCell><ReadMore text={row?.description} limit={20} /></StyledTableCell>
                                                        <StyledTableCell><ReadMore text={row?.associatedCommunity} limit={20} /></StyledTableCell>
                                                        <StyledTableCell >
                                                            <Box display={"flex"} gap={1} justifyContent={"center"}>
                                                                <Assets className={classes.viewBox} src={"/assets/icons/eye.svg"} absolutePath={true} onClick={() => handelView(row)} />
                                                            </Box>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            }) :
                                                <TableRow>
                                                    <TableCell colSpan={12}> <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} /></TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                ) : (
                                    <WidgetLoader />
                                )}
                            </TableContainer>
                        </Grid>
                    </Grid>
                    {siteData?.count > 0 &&
                        <Grid item xs={12}>
                            <CustomPagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={Math.ceil(siteData?.count / rowsPerPage)}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage} />

                        </Grid>}
                </PaperContainer>
            </Grid>

            {viewModel && (
                <CommonModal
                    open={viewModel}
                    onClose={handleClear}
                    title={`Site details`}
                    maxWidth={'lg'}
                    content={<ViewSiteModel
                        data={rowData}
                        filterImageData={filterImageData} />}
                />
            )}


            {isFilter && <SideDrawer
                drawerWidth={"25%"}
                anchor={"right"}
                open={isFilter}
                content={
                    <SitFiltercontent
                        filterData={filterData}
                        setFilterData={setFilterData}
                        projectList={projectList}
                        siteTypeList={siteTypeList}
                        siteCategoryList={siteCategoryList}
                        sitePopularityList={sitePopularityList}
                        ownershipList={ownershipList}
                        trailNameList={trailNameList}
                        handelFilterData={_handelFilterData}
                        handelClearFilter={_handelClearFilter}
                        stateList={states}
                        cityList={cities}
                    />
                }
                onClose={() => { setIsFilter(false); }}
                onSave={() => { '' }}
                header="All site filter"
            />}

        </>
    )
}

export default SiteTable