import React from 'react'
import { Grid, Box, Button } from "@mui/material";
import AutoCompleteMultiSelect from './AutoCompleteMultiSelect';

const SitFiltercontent = ({ filterData, setFilterData, projectList, siteTypeList, siteCategoryList, sitePopularityList, ownershipList, trailNameList, stateList, cityList, handelFilterData, handelClearFilter }) => {
    return (
        <Grid container spacing={2} xs={12} lg={12} sm={12} md={12}>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoCompleteMultiSelect
                    fullWidth
                    text="Project name"
                    placeholder={"Select project name"}
                    handleChange={(e, newValue) => { setFilterData({ ...filterData, projectName: newValue }) }}
                    options={projectList ? projectList : [].filter(ele => !filterData?.projectName?.some(select => select._id === ele._id))}
                    name="site"
                    getOptionLabel={(option) => option?.projectName || ''}
                    defaultValue={filterData?.projectName ? filterData.projectName : []}
                    mappingLabel='projectName'
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoCompleteMultiSelect
                    fullWidth
                    text="State"
                    placeholder={"Select state"}
                    handleChange={(e, newValue) => { setFilterData({ ...filterData, state: newValue }) }}
                    options={stateList ? stateList : [].filter(ele => !filterData?.state?.some(select => select._id === ele._id))}
                    name="site"
                    getOptionLabel={(option) => option?.name || ''}
                    defaultValue={filterData?.state ? filterData.state : []}
                    mappingLabel='name'
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoCompleteMultiSelect
                    fullWidth
                    text="District"
                    placeholder={"Select district"}
                    handleChange={(e, newValue) => { setFilterData({ ...filterData, city: newValue }) }}
                    options={cityList ? cityList : [].filter(ele => !filterData?.city?.some(select => select._id === ele._id))}
                    name="site"
                    getOptionLabel={(option) => option?.name || ''}
                    defaultValue={filterData?.city ? filterData.city : []}
                    mappingLabel='name'
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoCompleteMultiSelect
                    fullWidth
                    text="Site type"
                    placeholder={"Select site type"}
                    handleChange={(e, newValue) => { setFilterData({ ...filterData, siteType: newValue }) }}
                    options={siteTypeList ? siteTypeList : [].filter(ele => !filterData?.siteType?.some(select => select._id === ele._id))}
                    name="siteType"
                    getOptionLabel={(option) => option?.siteType || ''}
                    defaultValue={filterData?.siteType ? filterData.siteType : []}
                    mappingLabel='siteType'
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoCompleteMultiSelect
                    fullWidth
                    text="Site category"
                    placeholder={"Select site category"}
                    handleChange={(e, newValue) => { setFilterData({ ...filterData, siteCategory: newValue }) }}
                    options={siteCategoryList ? siteCategoryList : [].filter(ele => !filterData?.siteCategory?.some(select => select._id === ele._id))}
                    name="siteCategory"
                    getOptionLabel={(option) => option?.siteCategory || ''}
                    defaultValue={filterData?.siteCategory ? filterData.siteCategory : []}
                    mappingLabel='siteCategory'
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoCompleteMultiSelect
                    fullWidth
                    text="Site popularity"
                    placeholder={"Select popularity"}
                    handleChange={(e, newValue) => { setFilterData({ ...filterData, sitePopularity: newValue }) }}
                    options={sitePopularityList ? sitePopularityList : [].filter(ele => !filterData?.sitePopularity?.some(select => select._id === ele._id))}
                    name="sitePopularity"
                    getOptionLabel={(option) => option?.sitePopularity || ''}
                    defaultValue={filterData?.sitePopularity ? filterData.sitePopularity : []}
                    mappingLabel='sitePopularity'
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoCompleteMultiSelect
                    fullWidth
                    text="Ownership"
                    placeholder={"Select ownership"}
                    handleChange={(e, newValue) => { setFilterData({ ...filterData, ownership: newValue }) }}
                    options={ownershipList ? ownershipList : [].filter(ele => !filterData?.ownership?.some(select => select._id === ele._id))}
                    name="site"
                    getOptionLabel={(option) => option?.ownership || ''}
                    defaultValue={filterData?.ownership ? filterData.ownership : []}
                    mappingLabel='ownership'
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoCompleteMultiSelect
                    fullWidth
                    text="Trail name"
                    placeholder={"Select trail name"}
                    handleChange={(e, newValue) => { setFilterData({ ...filterData, trailName: newValue }) }}
                    options={trailNameList ? trailNameList : [].filter(ele => !filterData?.trailName?.some(select => select._id === ele._id))}
                    name="site"
                    getOptionLabel={(option) => option?.trailNumber || ''}
                    defaultValue={filterData?.trailName ? filterData.trailName : []}
                    mappingLabel='trailNumber'
                />
            </Grid>

            <Grid item lg={12} md={12} xs={12} sm={12}>
                <Box display={"flex"} justifyContent={"flex-end"} >
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                        <Button variant="outlined" color="primary" style={{ borderRadius: "25px", }} onClick={handelClearFilter}> Clear all</Button>
                        <Button variant="contained" color="primary" type="button" style={{ borderRadius: "25px" }} onClick={handelFilterData}>
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default SitFiltercontent
