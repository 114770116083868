import React, { useEffect, useState } from "react";
import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import { lightTheme } from "../../theme";
import { makeStyles } from "tss-react/mui";
import axios, { Image_BASE_URL } from "../../APiSetUp/axios";
import { useAppContext } from "../../Context/context";
import WidgetLoader from "../Common/widgetLoader";

const useStyles = makeStyles()((theme) => {
    return {
        card: {
            padding: "20px",
            borderRadius: "16px",
            border: "1px solid #F8F9FA",
            boxShadow: "0px 4px 20px 0px rgba(238, 238, 238, 0.50)",
            background: "#FFF",
        },
        cardImage: {
            height: "150px",
            border: "1px solid #EDF2F6",
            borderRadius: "16px",
            backgroundPosition: "top center",
        },
        cardContent: {
            display: "flex",
            justifyContent: "space-between",
            padding: "15px 5px",
            gap: "5px",
        },
        cardDescription: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 4,
            minHeight: "70px",
        },
    };
});

export const Offers = () => {
    const { classes } = useStyles();
    const { OnUpdateError, toggleLoader } = useAppContext();

    const [offerDetails, setOfferDetails] = useState([]);
    const [loading, setLoading] = useState(false)

    const _getOffer = () => {
        setLoading(true)
        axios
            .get("offer")
            .then((res) => {
                if (res?.data?.data) {
                    setOfferDetails(res?.data?.data);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => setLoading(false));
    };

    useEffect(() => {
        _getOffer()
    }, [])

    return (


        loading ? <Grid container spacing={1} item xs={12}>
            <Grid item xs={12} >
                <WidgetLoader />
            </Grid>
        </Grid> : offerDetails?.response?.length > 0 &&
        <>
            <TextLabel variant={"h6"} fontWeight={600} title={"Offers"} style={{ padding: "10px 0px 0px 10px" }} />
            <Grid container spacing={1} item xs={12} sm={12} md={12} lg={12} xl={12}>
                {offerDetails?.response?.map((item) => {
                    console.log(item, "itemitem")
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Card elevation={0} sx={{ border: `1px solid rgba(0, 0, 0, 0.1)`, height: '310px', borderRadius: '20px' }} >
                                <CardMedia
                                    sx={{ height: 140, margin: 1, border: `1px solid rgba(0, 0, 0, 0.1)`, borderRadius: '15px' }}
                                    image={`${Image_BASE_URL}${item?.image}`}
                                    title={item?.image}
                                />
                                <CardContent sx={{ padding: 1 }}>
                                    <Box width={'220px'}>
                                        <TextLabel fontSize={"18px"} color={"#000"} fontWeight={"600"} title={item?.title} noWrap />
                                    </Box>
                                </CardContent>
                                <CardActions>
                                    <Box width={'280px'}>
                                        <TextLabel className={classes.cardDescription} variant={"body2"} color={lightTheme.palette.bgLightExtraLightGray.main} fontWeight={"400"}
                                            title={<div dangerouslySetInnerHTML={{ __html: item?.description }} noWrap />}
                                        />
                                    </Box>
                                </CardActions>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}
