import React, { useState } from 'react'
import { Box, Grid,  TextField, InputLabel } from "@mui/material";
import { useTheme } from '@mui/styles';
import { makeStyles } from "tss-react/mui";
import TextLabel from '../Common/Fields/TextLabel';
import CommonButton from '../Common/Button/CommonButton';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AutoCompleteSearch from '../Common/commonAutoComplete';
import { lightTheme } from '../../theme';

const useStyles = makeStyles()((theme) => {
    return {
        dateBox: {
            background: theme?.palette?.bgWhite?.main,
            borderRadius: '8px',
            // minWidth: "50px",
            "& .MuiOutlinedInput-root": {
                borderRadius: "5px !important",
            },
            "& .MuiOutlinedInput-input": {
                padding: "12.5px 14px",
                fontSize: "14px !important",
                height: "18px",
            },
            "&:hover": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.backgroundDefaultColor?.main} !important`,
                borderRadius: "8px",
            },
            ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
                borderWidth: "1px !important",
            },
            "& .MuiInputBase-input.Mui-disabled": {
                backgroundColor: "#e0e0e0",
                borderRadius: '8px',
            }
        },
        customLabel: {
            "& .MuiTypography-root": {
                fontSize: "15px",
                color: "#151D48",
            },
        },
        deleteBox: {
            borderRadius: "6px",
            padding: "6px",
            color: lightTheme.palette.bgLightRed.main,
            backgroundColor: lightTheme.palette.bgLightExtraRed.main,
            cursor: "pointer",
            height: '26px',
        },
    };
});
const AddEmployeAttendance = ({ data, error, isEdit, onSubmit, setData, statusList, employeList }) => {
    const { classes } = useStyles();
    const theme = useTheme();

    return (
        <Box>
            <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <AutoCompleteSearch
                        text={"Employee"}
                        placeholder="please select employee"
                        fullWidth
                        backgroundColor="white"
                        name="employe"
                        handleChange={(e, newValue) => setData({ ...data, employe: newValue })}
                        options={employeList?.map((e) => e?.name) || []}
                        defaultValue={data?.employe}
                        freeSolo
                        blurOnSelect
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.employe ? error?.employe : ""} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box mt={0.5} display="flex" flexDirection={"row"}                                            >
                            <InputLabel sx={{ marginRight: "3px", fontWeight: 400, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>Attendance Date</InputLabel>
                        </Box>
                        <DesktopDatePicker
                            sx={{ width: '280px' }}
                            className={classes.dateBox}
                            inputFormat="MM/DD/YYYY"
                            value={data?.date ? dayjs(data?.date) : null}
                            onChange={(newValue) => { setData({ ...data, date: newValue }) }}
                            name='attendanceDate'
                            renderInput={(params) => <TextField {...params} />}
                            disablePast
                        />
                    </LocalizationProvider>
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.date ? error?.date : ""} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <AutoCompleteSearch
                        text={"Attendance Status"}
                        placeholder="please select status"
                        fullWidth
                        backgroundColor="white"
                        name="status"
                        handleChange={(e, newValue) => setData({ ...data, status: newValue })}
                        options={statusList?.map((e) => e?.name) || []}
                        defaultValue={data?.status}
                        freeSolo
                        blurOnSelect
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.status ? error?.status : ""} />
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box mt={0.5} display="flex" flexDirection={"row"}                                            >
                            <InputLabel sx={{ marginRight: "3px", fontWeight: 400, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>ClockIn Time</InputLabel>
                        </Box>
                        <MobileTimePicker
                            sx={{ width: '280px' }}
                            className={classes.dateBox}
                            value={data?.clockInTime ? dayjs(data?.clockInTime) : null}
                            onChange={(newValue) => { setData({ ...data, clockInTime: newValue }); }}
                            name='clockInTime'
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.clockInTime ? error?.clockInTime : ""} />
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box mt={0.5} display="flex" flexDirection={"row"}                                            >
                            <InputLabel sx={{ marginRight: "3px", fontWeight: 400, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>ClockOut Time</InputLabel>
                        </Box>
                        <MobileTimePicker
                            sx={{ width: '280px' }}
                            className={classes.dateBox}
                            value={data?.clockOutTime ? dayjs(data?.clockOutTime) : null}
                            onChange={(newValue) => { setData({ ...data, clockOutTime: newValue }); }}
                            name='clockOutTime'
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.clockOutTime ? error?.clockOutTime : ""} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
                        <CommonButton
                            width={'160px'}
                            text={`${isEdit ? "Update" : "Submit"} Attendance`}
                            type="submit"
                            onClick={onSubmit}
                        />
                    </Box>
                </Grid>
            </Grid >
        </Box>
    )
}

export default AddEmployeAttendance