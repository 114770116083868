import React from 'react'
import TextLabel from '../Common/Fields/TextLabel';
import { Box, Grid, useTheme } from '@mui/material';
import CommonButton from '../Common/Button/CommonButton';
import CommonTextField from '../Common/Fields/TextField';
import TextEditor from '../Common/textEditor';

const AddProject = ({ data, setData, error, handleChange, isEdit, onSubmit, handleImageUpload, output, setOutput }) => {
    const theme = useTheme()
    return (
        <>
            <Box>
                <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CommonTextField
                            fontWeight={400}
                            text={'Project Name'}
                            placeholder={"Enter project name"}
                            type='text'
                            name='projectName'
                            value={data?.projectName}
                            onChange={(e) => handleChange(e, false)}
                        />
                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.projectName ? error?.projectName : ""} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <CommonTextField
                            fontWeight={400}
                            text={'Total site'}
                            placeholder={"Enter t   otal site"}
                            type='number'
                            name='totalSite'
                            value={data?.totalSite}
                            onChange={(e) => handleChange(e, false)}
                        />
                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.totalSite ? error?.totalSite : ""} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <CommonTextField
                            fontWeight={400}
                            text={'Duration (in months)'}
                            placeholder={"Enter duration"}
                            type='number'
                            name='duration'
                            value={data?.duration}
                            onChange={(e) => handleChange(e, false)}
                        />
                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.duration ? error?.duration : ""} />
                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                        <CommonTextField
                            fontWeight={400}
                            text={'Apprvoed Site'}
                            placeholder={"Enter apprvoed site"}
                            type='number'
                            name='approvedSite'
                            value={data?.approvedSite}
                            onChange={(e) => handleChange(e, false)}
                        />
                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.approvedSite ? error?.approvedSite : ""} />
                    </Grid> */}

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <CommonTextField
                            fontWeight={400}
                            text={'Team members'}
                            placeholder={"Enter team members"}
                            type='number'
                            name='teamMembers'
                            value={data?.teamMembers}
                            onChange={(e) => handleChange(e, false)}
                        />
                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.teamMembers ? error?.teamMembers : ""} />
                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                        <CommonTextField
                            fontWeight={400}
                            text={'Status'}
                            placeholder={"Enter status"}
                            type='number'
                            name='status'
                            value={data?.status}
                            onChange={(e) => handleChange(e, false)}
                        />
                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.status ? error?.status : ""} />
                    </Grid> */}

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextEditor
                            defaultValue={data?.output}
                            category={"Output"}
                            onChange={(value) => { setOutput(value); }}
                        />
                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!output || output === "<p><br></p>" ? error?.output : ""} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
                            <CommonButton
                                width={'280px'}
                                text={`${isEdit ? "Update" : "Create"} Project`}
                                type="submit"
                                onClick={onSubmit}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}


export default AddProject