import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Table, TableRow, TableHead, Box, Grid, TableContainer, Tabs, Tab } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import { lightTheme } from "../../theme";
import { useAppContext } from "../../Context/context";
import axios from "../../APiSetUp/axios";
import { useLocation } from "react-router-dom";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WidgetLoader from "../../Components/Common/widgetLoader";
import CustomPagination from "../../Components/Common/CustomPagination";
import { projectStatus } from "../../Utils/enum";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        fontSize: 14,
        color: theme.palette.primary.main,
        fontFamily: "Poppins",
        whiteSpace: "nowrap",
        padding: 8
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Poppins",
        fontWeight: 500,
        padding: "8px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const useStyles = makeStyles()((theme) => {
    return {
        paddedRow: {
            padding: "15px 10px",
        },
        writeBox: {
            borderRadius: "6px",
            padding: "6px",
            backgroundColor: lightTheme.palette.bgLightExtraPrimary.main,
            color: lightTheme.palette.primary.main,
            cursor: "pointer",
            height: '26px'
        },
        viewBox: {
            borderRadius: "6px",
            padding: "6px",
            color: lightTheme.palette.bgLightSuccess.main,
            backgroundColor: lightTheme.palette.bgLightExtraSuccess.main,
            cursor: "pointer",
            height: '26px'
        },
        deleteBox: {
            borderRadius: "6px",
            padding: "6px",
            color: lightTheme.palette.bgLightRed.main,
            backgroundColor: lightTheme.palette.bgLightExtraRed.main,
            cursor: "pointer",
            height: '26px'
        },
    };
});


const ProjectTable = () => {
    const { classes } = useStyles();
    const { OnUpdateError, toggleLoader, menuList, user } = useAppContext();

    //States
    const [projectData, setProjectData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const _getSite = async () => {
        setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: search || ""
        };
        await axios.post(`/project`, body)
            .then((res) => {
                if (res?.data?.data) {
                    setProjectData(res?.data?.data);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => setLoading(false));
    };

    useEffect(() => {
        _getSite();
    }, [page, rowsPerPage, search]);

    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <PaperContainer elevation={0} square={false}>
                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid item xs={12}>
                            <TableHeading
                                title={"Project tracker"}
                                handleSearch={(value) => { setPage(1); setSearch(value); }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableContainer>
                                {!loading ? (
                                    <Table sx={{ minWidth: 600 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className={classes.paddedRow}>No.</StyledTableCell>
                                                <StyledTableCell>Project title</StyledTableCell>
                                                <StyledTableCell>Status</StyledTableCell>
                                                <StyledTableCell>Duration(in months)</StyledTableCell>
                                                <StyledTableCell>Total sites</StyledTableCell>
                                                <StyledTableCell>Approved sites</StyledTableCell>
                                                <StyledTableCell>Output</StyledTableCell>
                                            </TableRow>
                                        </TableHead>


                                        <TableBody>
                                            {projectData?.response?.length > 0 ? projectData?.response?.map((row, index) => {
                                                const getStatus = (type) => { return projectStatus.filter((e) => e?.id == type)?.[0]?.label; };

                                                return (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell className={classes.paddedRow}>{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                                        <StyledTableCell >{row?.projectName}</StyledTableCell>
                                                        <StyledTableCell >{getStatus(row.status)}</StyledTableCell>
                                                        <StyledTableCell >{`${row?.duration} months`}</StyledTableCell>
                                                        <StyledTableCell >{row?.totalSite}</StyledTableCell>
                                                        <StyledTableCell >{row?.approvedSite}</StyledTableCell>
                                                        <StyledTableCell ><div dangerouslySetInnerHTML={{ __html: row?.output }} noWrap /></StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            }) :
                                                <TableRow>
                                                    <TableCell colSpan={12}> <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} /></TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                ) : (
                                    <WidgetLoader />
                                )}
                            </TableContainer>
                        </Grid>
                    </Grid>
                    {projectData?.count > 0 &&
                        <Grid item xs={12}>
                            <CustomPagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={Math.ceil(projectData?.count / rowsPerPage)}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage} />

                        </Grid>}
                </PaperContainer>
            </Grid>
        </>
    )
}

export default ProjectTable