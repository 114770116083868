import React from 'react';
import { Grid, Typography, Card, CardMedia, CardContent, List, ListItem, ListItemText, Box, IconButton } from '@mui/material';
import { Image_BASE_URL } from '../../APiSetUp/axios';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { Visibility } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import PostAddIcon from '@mui/icons-material/PostAdd';


const ViewSite = ({ data, filterImageData }) => {
    const handaleViewImage = (img) => {
        window.open(`${Image_BASE_URL}${img}`);
    }

    const renderCard = (file, index, type) => (
        <Card key={index} sx={{ margin: 1, position: 'relative', width: 150 }}>
            {type === 'image' && (
                <CardMedia
                    component="img"
                    sx={{ height: 100, width: 150 }}
                    image={`${Image_BASE_URL}${file}`}
                    alt={`media-${index}`}
                />
            )}
            {type === 'video' && (
                <CardMedia component="video" sx={{ height: 100, width: 150 }} controls>
                    <source src={`${Image_BASE_URL}${file}`} />
                </CardMedia>
            )}
            {type === 'audio' && (
                <Box sx={{ height: 100, width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <audio controls style={{ width: '100%' }}>
                        <source src={`${Image_BASE_URL}${file}`} />
                    </audio>
                </Box>
            )}
            {type === 'pdf' && (
                <Box sx={{ height: 100, width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <DocumentScannerIcon fontSize="large" color="error" style={{ height: '80px', width: 'auto' }} />
                </Box>
            )}

            {type === 'document' && (
                <Box sx={{ height: 100, width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <DescriptionIcon fontSize="large" color="primary" style={{ height: '80px', width: 'auto' }} />
                </Box>
            )}
            {type === 'excel' && (
                <Box sx={{ height: 100, width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <PostAddIcon fontSize="large" color="success" style={{ height: '80px', width: 'auto' }} />
                </Box>
            )}

            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    opacity: 0,
                    transition: 'opacity 0.3s',
                    '&:hover': {
                        opacity: 1,
                    },
                }}
            >
                <IconButton
                    sx={{ color: 'white', margin: 1 }}
                    onClick={() => handaleViewImage(file)}
                >
                    <Visibility />
                </IconButton>
            </Box>
        </Card>
    );

    return (
        <Box sx={{ margin: "15px" }}>
            <Typography style={{ marginTop: "12px" }} variant="h4" gutterBottom>{data?.siteName}</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ marginTop: "14px" }}>
                        <CardMedia component="img" height="140" image={data?.sietLogo ? `${Image_BASE_URL}${data?.sietLogo}` : '/path/to/default-image.jpg'} alt="Site Logo" />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {data?.project}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {data?.location}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={8}>
                    <List>
                        <ListItem>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    <ListItemText primary="State" secondary={data?.state} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    <ListItemText primary="City" secondary={data?.city} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    <ListItemText primary="Latitude" secondary={data?.latitude} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    <ListItemText primary="Longitude" secondary={data?.longitude} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    <ListItemText primary="Type" secondary={data?.siteType} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    <ListItemText primary="Category" secondary={data?.siteCategory} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    <ListItemText primary="Sub Category" secondary={data?.subCategory} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    <ListItemText primary="Popularity" secondary={data?.sitePopularity} />
                                </Grid>

                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    <ListItemText primary="State of Conservation" secondary={data?.conservation} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    <ListItemText primary="Graded" secondary={data?.graded} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    <ListItemText primary="Ownership" secondary={data?.ownership} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    <ListItemText primary="Researcher" secondary={data?.employeId} />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <ListItemText primary="Description" secondary={data?.description} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <ListItemText primary="Associated Community" secondary={data?.associatedCommunity} />
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>

            <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
                {filterImageData?.imageData?.length > 0 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>Images</Typography>
                        <Box display="flex" flexWrap="wrap">
                            {filterImageData.imageData.map((file, index) => renderCard(file, index, 'image'))}
                        </Box>
                    </Box>
                )}
                {filterImageData?.videoData?.length > 0 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>Videos</Typography>
                        <Box display="flex" flexWrap="wrap">
                            {filterImageData.videoData.map((file, index) => renderCard(file, index, 'video'))}
                        </Box>
                    </Box>
                )}
                {filterImageData?.pdfData?.length > 0 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>PDFs</Typography>
                        <Box display="flex" flexWrap="wrap">
                            {filterImageData.pdfData.map((file, index) => renderCard(file, index, 'pdf'))}
                        </Box>
                    </Box>
                )}
                {filterImageData?.documentData?.length > 0 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>Documents</Typography>
                        <Box display="flex" flexWrap="wrap">
                            {filterImageData.documentData.map((file, index) => renderCard(file, index, 'document'))}
                        </Box>
                    </Box>
                )}

                {filterImageData?.excelData?.length > 0 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>Excels</Typography>
                        <Box display="flex" flexWrap="wrap">
                            {filterImageData.excelData.map((file, index) => renderCard(file, index, 'excel'))}
                        </Box>
                    </Box>
                )}

                {filterImageData?.audioData?.length > 0 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>Audios</Typography>
                        <Box display="flex" flexWrap="wrap">
                            {filterImageData.audioData.map((file, index) => renderCard(file, index, 'audio'))}
                        </Box>
                    </Box>
                )}
            </Grid>
        </Box>
    );
};

export default ViewSite

