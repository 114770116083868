import React, { useEffect, useState } from 'react'
import { Box, Card, CardMedia, Grid, IconButton, Typography } from "@mui/material";
import TextLabel from '../Common/Fields/TextLabel';
import CommonTextField from '../Common/Fields/TextField';
import CommonButton from '../Common/Button/CommonButton';
import AutoCompleteSearch from '../Common/commonAutoComplete';
import { Roles } from '../../Utils/enum';
import { useAppContext } from '../../Context/context';
import { useTheme } from '@emotion/react';
import CommonFileDrop from '../Common/CommonFileDrop';
import { lightTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';
import { Image_BASE_URL } from '../../APiSetUp/axios';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import FileUpload from '../Common/uploadButton';
import { Delete, Visibility } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import PostAddIcon from '@mui/icons-material/PostAdd';


const useStyles = makeStyles()((theme) => {
    return {
        viewBox: {
            borderRadius: "6px",
            padding: "6px",
            color: lightTheme.palette.bgLightSuccess.main,
            cursor: "pointer",
            height: '26px',
            marginLeft: "6px"
        },
        siteImagesBox: {
            border: "1px dashed #ccc",
            "&:hover": { backgroundColor: theme.palette.bgLightExtraPrimary.main },
            borderRadius: "8px",
            display: 'flex',
            width: '100%',
            height: '70px',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 1,

        },
        siteAudioBox: {
            border: "1px dashed #ccc",
            "&:hover": { backgroundColor: theme.palette.bgLightExtraPrimary.main },
            borderRadius: "8px",
            display: 'flex',
            width: '100%',
            height: '70px',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'start',
            alignItems: 'center',
            padding: 1,

        },
    };
});


const AddSite = ({ data, error, handleChange, isEdit, onSubmit, setData, handleUpload, handleDeleteFile, siteTypeList,
    files, setFiles, addAudioFile, deleteFiled, setViewModel, projectList, states, cities, siteCategoryList, sitePopularityList, ownershipList, employeList, handleImageUpload }) => {
    const { classes } = useStyles();
    const theme = useTheme();
    const { OnUpdateError, toggleLoader, menuList, user } = useAppContext();

    const [imageData, setImageData] = useState({
        imageData: [],
        pdfData: [],
        documentData: [],
        excelData: [],
        videoData: [],
        audioData: []
    })

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const videoExtensions = ['mp4', 'avi', 'mov'];
    const pdfExtensions = ['pdf'];
    const doumentsExtensions = ['doc', 'docx',];
    const excelExtensions = ['xls', 'xlsx'];
    const audioExtensions = ['mp3', 'wav'];

    const getExtension = (filename) => {
        return filename?.split('.').pop().toLowerCase();
    };

    useEffect(() => {
        if (files && files.length > 0) {
            setImageData({
                ...data,
                imageData: files.filter(ele => imageExtensions.includes(getExtension(ele))),
                pdfData: files.filter(ele => pdfExtensions.includes(getExtension(ele))),
                documentData: files.filter(ele => doumentsExtensions.includes(getExtension(ele))),
                excelData: files.filter(ele => excelExtensions.includes(getExtension(ele))),
                videoData: files.filter(ele => videoExtensions.includes(getExtension(ele))),
                audioData: files.filter(ele => audioExtensions.includes(getExtension(ele)))
            })
        }
    }, [files])

    const handaleViewImage = (img) => {
        window.open(`${Image_BASE_URL}${img}`);
    }

    const renderCard = (file, index, type) => (
        <Card key={index} sx={{ margin: 1, position: 'relative', width: 150 }}>
            {type === 'image' && (
                <CardMedia
                    component="img"
                    sx={{ height: 100, width: 150 }}
                    image={`${Image_BASE_URL}${file}`}
                    alt={`media-${index}`}
                />
            )}
            {type === 'video' && (
                <CardMedia component="video" sx={{ height: 100, width: 150 }} controls>
                    <source src={`${Image_BASE_URL}${file}`} />
                </CardMedia>
            )}
            {type === 'audio' && (
                <Box sx={{ height: 100, width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <audio controls style={{ width: '100%' }}>
                        <source src={`${Image_BASE_URL}${file}`} />
                    </audio>
                </Box>
            )}
            {type === 'pdf' && (
                <Box sx={{ height: 100, width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <DocumentScannerIcon fontSize="large" color="error" style={{ height: '80px', width: 'auto' }} />
                </Box>
            )}
            {type === 'document' && (
                <Box sx={{ height: 100, width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <DescriptionIcon fontSize="large" color="primary" style={{ height: '80px', width: 'auto' }} />
                </Box>
            )}
            {type === 'excel' && (
                <Box sx={{ height: 100, width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <PostAddIcon fontSize="large" color="success" style={{ height: '80px', width: 'auto' }} />
                </Box>
            )}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    opacity: 0,
                    transition: 'opacity 0.3s',
                    '&:hover': {
                        opacity: 1,
                    },
                }}
            >
                <IconButton
                    sx={{ color: 'white', margin: 1 }}
                    onClick={() => handaleViewImage(file)}
                >
                    <Visibility />
                </IconButton>
                <IconButton
                    sx={{ color: 'white', margin: 1 }}
                    onClick={() => handleDeleteFile(file)}
                >
                    <Delete />
                </IconButton>
            </Box>
        </Card>
    );

    return (
        <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <AutoCompleteSearch
                    text={"Project Name"}
                    placeholder="please select Project"
                    fullWidth
                    backgroundColor="white"
                    name="project"
                    handleChange={(e, newValue) => setData({ ...data, project: newValue })}
                    options={projectList?.map((e) => e?.projectName) || []}
                    defaultValue={data?.project}
                    freeSolo
                    blurOnSelect
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.project ? error?.project : ""} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
                <AutoCompleteSearch
                    text="State Name"
                    fullWidth
                    backgroundColor="white"
                    handleChange={(e, newValue) => {
                        setData({ ...data, state: newValue })
                        if (isEdit && data?.state !== newValue) {
                            setData({ ...data, state: newValue })
                        }
                    }}
                    options={states?.map((e) => e?.name) || []}
                    name="label"
                    defaultValue={data?.state || ""}
                    freeSolo
                    blurOnSelect
                    placeholder={"Select Country"}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.state ? error?.state : ""} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
                <AutoCompleteSearch
                    backgroundColor="white"
                    fullWidth
                    text="District"
                    handleChange={(e, newValue) => setData({ ...data, city: newValue })}
                    options={cities?.map((e) => e?.name) || []}
                    name="label"
                    defaultValue={data?.city || ""}
                    freeSolo
                    blurOnSelect
                    placeholder={"Select District"}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.city ? error?.city : ""} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
                <CommonTextField
                    fontWeight={400}
                    text={'Site name'}
                    placeholder={"Please enter site name"}
                    type='text'
                    name='siteName'
                    value={data?.siteName}
                    onChange={handleChange}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.siteName ? error?.siteName : ""} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
                <AutoCompleteSearch
                    text={"Site type"}
                    placeholder="please select site type"
                    fullWidth
                    backgroundColor="white"
                    name="siteType"
                    handleChange={(e, newValue) => setData({ ...data, siteType: newValue })}
                    options={siteTypeList?.map((e) => e?.siteType) || []}
                    defaultValue={data?.siteType}
                    freeSolo
                    blurOnSelect
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.siteType ? error?.siteType : ""} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
                <CommonTextField
                    fontWeight={400}
                    text={'Location'}
                    placeholder={"Please enter location"}
                    type='text'
                    name='location'
                    value={data?.location}
                    onChange={handleChange}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.location ? error?.location : ""} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
                <CommonTextField
                    fontWeight={400}
                    text={'Latitude'}
                    placeholder={"Please enter latitude."}
                    type='number'
                    name='latitude'
                    value={data?.latitude}
                    onChange={(e) => handleChange(e, false)}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.latitude ? error?.latitude : ""} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
                <CommonTextField
                    fontWeight={400}
                    text={'Longitude'}
                    placeholder={"Please enter longitude."}
                    type='number'
                    name='longitude'
                    value={data?.longitude}
                    onChange={(e) => handleChange(e, false)}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.longitude ? error?.longitude : ""} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
                <AutoCompleteSearch
                    text={"Site category"}
                    placeholder="please select site category"
                    fullWidth
                    backgroundColor="white"
                    name="siteCategory"
                    handleChange={(e, newValue) => setData({ ...data, siteCategory: newValue })}
                    options={siteCategoryList?.map((e) => e?.siteCategory) || []}
                    defaultValue={data?.siteCategory}
                    freeSolo
                    blurOnSelect
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.siteCategory ? error?.siteCategory : ""} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
                <CommonTextField
                    fontWeight={400}
                    text={'Sub category'}
                    placeholder={"Please enter sub category"}
                    type='text'
                    name='subCategory'
                    value={data?.subCategory}
                    onChange={handleChange}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.subCategory ? error?.subCategory : ""} />
            </Grid>


            <Grid item xs={12} sm={6} md={4} lg={4}>
                <AutoCompleteSearch
                    text={"Site popularity"}
                    placeholder="please select site popularity"
                    fullWidth
                    backgroundColor="white"
                    name="sitePopularity"
                    handleChange={(e, newValue) => setData({ ...data, sitePopularity: newValue })}
                    options={sitePopularityList?.map((e) => e?.sitePopularity) || []}
                    defaultValue={data?.sitePopularity}
                    freeSolo
                    blurOnSelect
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.sitePopularity ? error?.sitePopularity : ""} />
            </Grid>

            {<Grid item xs={12} sm={6} md={4} lg={4}>
                <AutoCompleteSearch
                    text={"Ownership"}
                    placeholder="please ownership"
                    fullWidth
                    backgroundColor="white"
                    name="ownership"
                    handleChange={(e, newValue) => setData({ ...data, ownership: newValue })}
                    options={ownershipList?.map((e) => e?.ownership) || []}
                    defaultValue={data?.ownership}
                    freeSolo
                    blurOnSelect
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.ownership ? error?.ownership : ""} />
            </Grid>}

            <Grid item xs={12} sm={6} md={4} lg={4}>
                <CommonTextField
                    fontWeight={400}
                    text={'State of conservation'}
                    placeholder={"Please enter state of conservation"}
                    type='text'
                    name='conservation'
                    value={data?.conservation}
                    onChange={(e) => handleChange(e, false)}
                    rows={2}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.conservation ? error?.conservation : ""} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
                <CommonTextField
                    fontWeight={400}
                    text={'Graded/Non-Graded'}
                    placeholder={"Please enter Graded/Non-Graded"}
                    type='text'
                    name='graded'
                    value={data?.graded}
                    onChange={(e) => handleChange(e, false)}
                    rows={2}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.graded ? error?.graded : ""} />
            </Grid>

            {user?.userType !== Roles?.Employe && <Grid item xs={12} sm={6} md={4} lg={4}>
                <AutoCompleteSearch
                    text={"Researcher"}
                    placeholder="please select researcher"
                    fullWidth
                    backgroundColor="white"
                    name="researcher"
                    handleChange={(e, newValue) => setData({ ...data, employeId: newValue })}
                    options={employeList?.map((e) => e?.name) || []}
                    defaultValue={data?.employeId}
                    freeSolo
                    blurOnSelect
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.employeId ? error?.employeId : ""} />
            </Grid>}

            <Grid item xs={12} sm={6} md={6} lg={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} marginTop={1} display={"flex"}>
                    <TextLabel fontSize={"14px"} color={"#151D48"} fontWeight={"400"} title={"Description/Significance"} marginBottom={5} secondText={' (20-word limit)'} secondTextFontSize="10px" secondTextColor={theme.palette.backgroundDefaultColor.main} />
                </Grid>
                <CommonTextField
                    fontWeight={400}
                    placeholder={"Please enter description/significance"}
                    type='text'
                    name='description'
                    value={data?.description}
                    onChange={(e) => handleChange(e, false)}
                    rows={2}
                    multiline
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.description ? error?.description : ""} style={{ marginTop: "36px" }} />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} marginTop={1} display={"flex"}>
                    <TextLabel fontSize={"14px"} color={"#151D48"} fontWeight={"400"} title={"Associated Community"} marginBottom={5} secondText={' (20-word limit)'} secondTextFontSize="10px" secondTextColor={theme.palette.backgroundDefaultColor.main} />
                </Grid>
                <CommonTextField
                    fontWeight={400}
                    placeholder={"Please enter associated community"}
                    type='text'
                    name='associatedCommunity'
                    value={data?.associatedCommunity}
                    onChange={(e) => handleChange(e, false)}
                    rows={2}
                    multiline
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.associatedCommunity ? error?.associatedCommunity : ""} style={{ marginTop: "36px" }} />
            </Grid>



            <Grid item xs={12} sm={6} md={6} lg={6}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextLabel fontSize={"15px"} color={"#151D48"} fontWeight={"400"} title={'Site logo'} style={{ padding: '3px' }} />
                </Grid>
                <FileUpload
                    handleFileChange={(e) => handleImageUpload(e.target.files[0])}
                    selectedFile={data?.sietLogo}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.sietLogo ? error?.sietLogo : ""} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} >
                <Grid item xs={12} sm={12} md={12} lg={12} marginTop={1} display={"flex"}>
                    <TextLabel fontSize={"14px"} color={"#151D48"} fontWeight={"400"} title={"Upload File"} marginBottom={5} secondText={' (Optional)'} secondTextFontSize="10px" secondTextColor={theme.palette.backgroundDefaultColor.main} />
                </Grid>
                <CommonFileDrop
                    isMultiple={true}
                    value={""}
                    handleImgClose={() => { }}
                    values={""}
                    setFieldValue={""}
                    handleImageChange={(e) => handleUpload(e)}
                    handleFileChange
                    sx={{ height: "136px", cursor: "grab" }}
                    accept="
                    image/png, 
                    image/jpeg, 
                    image/jpg, 
                    image/svg+xml, 
                    image/webp, 
                    application/pdf, 
                    application/msword, 
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
                    application/vnd.ms-excel, 
                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
                    application/vnd.ms-excel.sheet.macroEnabled.12, 
                    application/vnd.openxmlformats-officedocument.spreadsheetml.template, 
                    application/vnd.ms-excel.template.macroEnabled.12, 
                    application/x-msdownload, 
                    audio/mpeg, 
                    audio/wav, 
                    audio/mp3, 
                    video/mp4, 
                    video/x-msvideo
                "
                    name="productImage"
                />
            </Grid>

            <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
                {imageData?.imageData?.length > 0 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>Images</Typography>
                        <Box display="flex" flexWrap="wrap">
                            {imageData.imageData.map((file, index) => renderCard(file, index, 'image'))}
                        </Box>
                    </Box>
                )}
                {imageData?.videoData?.length > 0 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>Videos</Typography>
                        <Box display="flex" flexWrap="wrap">
                            {imageData.videoData.map((file, index) => renderCard(file, index, 'video'))}
                        </Box>
                    </Box>
                )}
                {imageData?.pdfData?.length > 0 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>PDFs</Typography>
                        <Box display="flex" flexWrap="wrap">
                            {imageData.pdfData.map((file, index) => renderCard(file, index, 'pdf'))}
                        </Box>
                    </Box>
                )}

                {imageData?.documentData?.length > 0 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>Documents</Typography>
                        <Box display="flex" flexWrap="wrap">
                            {imageData.documentData.map((file, index) => renderCard(file, index, 'document'))}
                        </Box>
                    </Box>
                )}

                {imageData?.excelData?.length > 0 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>Excels</Typography>
                        <Box display="flex" flexWrap="wrap">
                            {imageData.excelData.map((file, index) => renderCard(file, index, 'excel'))}
                        </Box>
                    </Box>
                )}
                {imageData?.audioData?.length > 0 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>Audios</Typography>
                        <Box display="flex" flexWrap="wrap">
                            {imageData.audioData.map((file, index) => renderCard(file, index, 'audio'))}
                        </Box>
                    </Box>
                )}
            </Grid>

            {/* <Grid item xs={12} sm={12} md={12} lg={12} marginTop={2}  >
                    <Box style={{ display: 'flex', justifyContent: 'end' }}>
                        <CommonButton
                            startIcon={<AddOutlinedIcon />}
                            width={'110px'}
                            text={`Add File`}
                            type="button"
                            onClick={addAudioFile}
                        />
                    </Box>
                </Grid> */}

            {/* {files.map((audioFile, index) => (
                    <>
                        <Grid item xs={11} sm={11} md={5} lg={5} key={index}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextLabel fontSize={"14px"} color={"#151D48"} fontWeight={"400"} title={"Upload Audio File"} marginBottom={5} secondText={' (Optional)'} secondTextFontSize="10px" secondTextColor={theme.palette.backgroundDefaultColor.main} />
                            </Grid>
                            <FileUpload
                                handleFileChange={(e) => { handleUpload(e.target.files[0], index) }}
                                selectedFile={audioFile}
                                OnDelate={(e) => handleDeleteFile(e, index)}
                            />
                            <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={error?.audioSizeValid} />
                        </Grid>

                        {index >= 1 ? <Grid item xs={1} sm={1} md={1} lg={1} key={index} style={{ display: "flex", alignItems: 'center', marginTop: "28px" }}>
                            <Assets
                                className={classes.deleteBox}
                                src={"/assets/icons/delete.svg"}
                                absolutePath={true}
                                onClick={(ele) => { deleteFiled(ele, index) }}
                            />
                        </Grid> : <Grid item xs={1} sm={1} md={1} lg={1} key={index}></Grid>}
                    </>
                ))} */}

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
                    <CommonButton width={'160px'} text={`${isEdit ? "Update" : "Submit"} Form`} type="submit" onClick={onSubmit} />
                </Box>
            </Grid>
        </Grid >
    )
}

export default AddSite;