import React from 'react'

const SiteIcon = () => {
  return (
    
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 8.27V4.23C22 2.64 21.36 2 19.77 2H15.73C14.14 2 13.5 2.64 13.5 4.23V8.27C13.5 9.86 14.14 10.5 15.73 10.5H19.77C21.36 10.5 22 9.86 22 8.27Z" stroke="#717791" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z" stroke="#717791" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z" stroke="#717791" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.5 17.5H20.5" stroke="#717791" stroke-width="1.5" stroke-linecap="round"/>
<path d="M17.5 20.5V14.5" stroke="#717791" stroke-width="1.5" stroke-linecap="round"/>
</svg>

  )
}

export default SiteIcon
