import React from "react";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import clsx from "clsx";
import { lightTheme } from "../../theme";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },

    drawerPaper: {
        width: "100%",
        height: "100%",
        backgroundColor: ({ bgColor }) => bgColor || theme.palette.background.paper,
        [theme.breakpoints.up("md")]: {
            width: ({ fullmode, drawerWidth }) =>
                fullmode ? "100%" : drawerWidth || 420,
        },
    },
    customdrawerPaper: {
        width: "100%",
        height: "100%",
        [theme.breakpoints.up("md")]: {
            width: ({ fullmode, drawerWidth }) =>
                fullmode ? "100%" : drawerWidth || 520,
        },
    },
    drawerHeader: {
        flex: "none",
        padding: "12px",
        backgroundColor: lightTheme.palette.primary.main,
        color: "#fff",
    },
    drawerFooter: {
        flex: "none",
        padding: "12px",
        "& button": {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
    },
    drawerContent: {
        flex: "1 0",
        width: "100%",
        padding: "12px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "0.4rem",
        },
    },
    noPadding: {
        padding: 0,
    },
    fullHeight: {
        height: "100%",
    },

}));

function SideDrawer(props) {
    const classes = useStyles(props);
    const handleClose = (event, reason) => {
        props.onClose(event, reason);
    };
    return (
        <React.Fragment>
            <Drawer
                ModalProps={props.disableBackdropClick ? props.disableBackdropClick : { disableBackdropClick: true, disableEscapeKeyDown: true }}
                anchor={props.anchor || "right"}
                open={props.open}
                onClose={handleClose}
                classes={{
                    paper: props.isCustomMode
                        ? classes.customdrawerPaper
                        : classes.drawerPaper,
                }}
            >
                {
                    <div className={classes.root}>
                        <div className={classes.drawerHeader}>
                            <Grid container spacing={1}>
                                <Grid item container style={{ alignItem: "center" }} xs={12}>
                                    <Grid item xs={10}>
                                        <Typography style={{ fontWeight: "bold" }}>
                                            {props.header || "Header title"}
                                        </Typography>
                                        {props.subHeader ? <Typography variant="caption">
                                            {props.subHeader}
                                        </Typography> : null}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{ textAlign: "right" }}
                                        onClick={props.onClose}
                                    >
                                        <HighlightOffIcon style={{ fontSize: "1.25rem" }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <Divider />
                        <div
                            className={clsx(
                                classes.drawerContent,
                                props.noPadding && classes.noPadding
                            )}
                        >
                            <Grid
                                container
                                className={clsx(props.fullHeight && classes.fullHeight)}
                                spacing={props.noPadding ? 0 : 1}
                            >
                                <Grid item xs={12}>
                                    {props.content}
                                </Grid>
                            </Grid>
                        </div>
                        <Divider />
                    </div>
                }
            </Drawer>
        </React.Fragment>
    );
}
export default SideDrawer;
