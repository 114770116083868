import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import {
  Table,
  TableRow,
  TableHead,
  TableContainer,
  Box,
  Grid,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Assets from "../../Components/Common/ImageContainer";
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import CommonModal from "../../Components/Common/CommonModel";
import { useAppContext } from "../../Context/context";
import axios from "../../APiSetUp/axios";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { lightTheme } from "../../theme";
import { permissionStatus } from "../../Utils/enum";
import WidgetLoader from "../../Components/Common/widgetLoader";
import Swal from "sweetalert2";
import CustomPagination from "../../Components/Common/CustomPagination";
import AddTrailNumber from "../../Components/TrailNumber";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.primary.main,
    fontFamily: "Poppins",
    padding: 5,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles()((theme) => {
  return {
    paddedRow: {
      padding: "15px 10px",
    },
    writeBox: {
      borderRadius: "6px",
      padding: "8px",
      backgroundColor: lightTheme.palette.bgLightExtraPrimary.main,
      color: lightTheme.palette.primary.main,
      cursor: "pointer",
    },
    viewBox: {
      borderRadius: "6px",
      padding: "8px",
      color: lightTheme.palette.bgLightSuccess.main,
      backgroundColor: lightTheme.palette.bgLightExtraSuccess.main,
      cursor: "pointer",
    },
    deleteBox: {
      borderRadius: "6px",
      padding: "8px",
      color: lightTheme.palette.bgLightRed.main,
      backgroundColor: lightTheme.palette.bgLightExtraRed.main,
      cursor: "pointer",
    },
  };
});

const TrailName = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const { pathname } = location;

  const { OnUpdateError, toggleLoader, menuList } = useAppContext();

  //States
  const [model, setModel] = useState(false);
  const [data, setData] = useState({
    trailNumber: '',
    site: [],
    description: '',
  });
  const [error, setError] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [brancesDetails, setBrancheDetails] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [permissions, setPermissions] = useState({});
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false)
  const [siteList, setSiteList] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  console.log('data?.site', data)

  //Validation
  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};
    if (!data?.trailNumber) {
      formIsValid = false;
      errors["trailNumber"] = "Please enter trail name.";
    }
    if (!data?.site || data?.site?.length < 1) {
      formIsValid = false;
      errors["site"] = "Please select the site";
    }
    setError(errors);
    return formIsValid;
  };
  console.log('data?.site?.length ', data?.site?.length)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const _getTrailName = () => {
    setLoading(true)
    let body = {
      limit: rowsPerPage,
      page: page,
      search: search || ""
    };
    axios.post(`/trailNumber`, body)
      .then((res) => {
        if (res?.data?.data) {
          setBrancheDetails(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };

  const handleClear = () => {
    setModel(false);
    setData({});
    setError({});
    setIsEdit(false);
  };

  const _addUpdateTrailName = () => {
    if (handleValidation()) {
      toggleLoader();
      let body = {
        trailNumber: data?.trailNumber,
        site: data?.site?.length > 0 ? data?.site.map((item) => item._id) : null,
      };
      if (data?.description) {
        body.description = data?.description
      }
      if (data?._id) {
        body.id = data?._id;
      }
      axios
        .post(`trailNumber/${data?._id ? "update" : "create"}`, body)
        .then((res) => {
          if (res?.data?.data) {
            swal("Success", res?.data?.message, "success");
            handleClear();
            _getTrailName();
          }
          toggleLoader();
        })
        .catch((err) => {
          toggleLoader();
          OnUpdateError(err.data.message);
        });
    }
  };

  const _handleDelete = (deleteId) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: "error",
      html: "Are you sure you want to delete trail name",
      showCancelButton: true,
      confirmButtonColor: "#0492c2",
      iconColor: "#ff0000",
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      if (result.isConfirmed) {
        toggleLoader();
        axios
          .delete(`/trailNumber/delete/${deleteId}`)
          .then((res) => {
            swal("Success", res?.data?.message, "success");
            toggleLoader();
            _getTrailName();
          })
          .catch((err) => {
            toggleLoader();
            OnUpdateError(err.data.message);
          });
      }
    });
  };

  const _getSiteList = () => {
    setLoading(true)
    axios.get(`/siteList`)
      .then((res) => {
        if (res?.data?.data) {
          setSiteList(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };

  useEffect(() => {
    if (model) {
      _getSiteList()
    }
  }, [model])

  useEffect(() => {
    _getTrailName();
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    const menu = menuList?.find((e) => e?.path === pathname);
    if (menu) {
      const menuPermissions = menu.permissions;
      setPermissions({
        view: menuPermissions.includes(permissionStatus.view) ? true : false,
        create: menuPermissions.includes(permissionStatus.create) ? true : false,
        update: menuPermissions.includes(permissionStatus.update) ? true : false,
        delete: menuPermissions.includes(permissionStatus.delete) ? true : false,
      });
    }
  }, [menuList, location]);

  return (
    <>
      <PaperContainer elevation={0} square={false}>
        <Grid container>
          <Grid item xs={12}>
            <TableHeading
              title="Trail Number"
              buttonText={permissions?.create ? `Add Trail Number` : ""}
              onClick={() => setModel(true)}
              handleSearch={(value) => { setPage(1); setSearch(value); }}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              {!loading ? (
                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className={classes.paddedRow}>No.</StyledTableCell>
                      <StyledTableCell>Trail Name</StyledTableCell>
                      <StyledTableCell>Total site</StyledTableCell>
                      <StyledTableCell>Description</StyledTableCell>
                      <StyledTableCell align="center" >Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {brancesDetails?.response?.length > 0 ? brancesDetails?.response?.map((row, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell className={classes.paddedRow}>
                            {(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">{row.trailNumber}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{row.site.length}</StyledTableCell>
                          <StyledTableCell>{row.description ? row.description : "-"}</StyledTableCell>
                          <StyledTableCell>
                            <Box
                              display={"flex"} gap={1} justifyContent={"center"}
                            >
                              {permissions?.update && (
                                <Assets
                                  className={classes.writeBox}
                                  src={"/assets/icons/write.svg"}
                                  absolutePath={true}
                                  onClick={() => {
                                    setData({ ...row, site: row?.siteDetails });
                                    setIsEdit(true);
                                    setModel(true);
                                  }}
                                />
                              )}
                              {permissions?.delete && (
                                <Assets
                                  className={classes.deleteBox}
                                  src={"/assets/icons/delete.svg"}
                                  absolutePath={true}
                                  onClick={() => {
                                    _handleDelete(row?._id);
                                  }}
                                />
                              )}
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    }) :
                      <TableRow>
                        <TableCell colSpan={12}> <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              ) : (
                <WidgetLoader />
              )}
            </TableContainer>
          </Grid>
          {brancesDetails?.count > 0 && <Grid item xs={12}>
            <CustomPagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={Math.ceil(brancesDetails?.count / rowsPerPage)}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage} />
          </Grid>
          }
        </Grid>

      </PaperContainer>

      {model && (
        <CommonModal
          open={model}
          onClose={handleClear}
          title={`${isEdit ? "Update" : "Add"} Trail Number`}
          content={
            <AddTrailNumber
              data={data}
              setData={setData}
              error={error}
              handleChange={handleChange}
              onSubmit={_addUpdateTrailName}
              isEdit={isEdit}
              siteList={siteList}

            />
          }
        />
      )}
    </>
  );
};

export default TrailName;
